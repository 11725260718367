import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import "./App.css";

import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import AboutPage from "./pages/AboutPage";
import TeamMember from "./pages/TeamMember";
import ContactPage from "./pages/ContactPage";
import TeamPage from "./pages/TeamPage";
import JoinUsPage from "./pages/JoinUsPage";
import ServicesLandingPage from "./pages/ServicesLandingPage";
import ContentConsulting from "./pages/content/ContentConsulting";
import ContentAdvocacy from "./pages/content/ContentAdvocacy";
import ContentResearch from "./pages/content/ContentResearch";
import ContentManagement from "./pages/content/ContentManagement";

const imageUrls = [
  "../images/Bilguuntugs.jpg",
  "../images/Azbayar.jpg",
  "../images/Otgonjargal.jpg",
  "../images/Altantsetseg.jpg",
  "../images/Shinegerel.jpg",
  "../images/Narantsetseg.jpg",
  "../images/Tsolmon.jpg",
  "../images/Hulan.jpg",
  "../images/Otgon.jpg",
  "../images/Guljan.jpg",
  "../images/hallway.jpg",
  "../images/autumn-columns-blurred.jpg",
  "../images/all.jpg",
  "../images/logo.png",
  "../images/logowall.jpg",
  "../images/Naranbaatar-320x320.jpg",
  "../images/Battulga-320x320.jpg",
  "../images/Otgonbat-320x320.jpg",
  // "../images/group3.jpg",
  // "../images/group4.jpg",
  // "../images/group5.jpg",
  // "../images/group6.jpg",
  // "../images/group1.jpg",
  // "../images/group2.jpg",
  // "../images/content1.jpg",
  // "../images/content2.jpg",
  // "../images/content3.jpg",
  // "../images/content4.jpg",
  // "../images/content5.jpg",
  // "../images/ub1.jpg",
  // "../images/ub2.jpg",
  // "../images/ub3.jpg",
  // "../images/office.jpg",
  // "../images/bluesky-night.jpg",
  // "../images/bluesky.jpg",
  // "../images/books-blurred.jpg",
  // "../images/business-desk-blurred.jpg",
  // "../images/business-meeting.jpg",
  // "../images/columns.jpg",
  // "../images/bluesky-dusk.jpg",
  // "../images/abstract.jpg",
  // "../images/skyscraper.jpg",
  // "../images/study-blurred.jpg",
  // "../images/signature-blurred.jpg",
];
const totalImages = imageUrls.length;

const App = () => {
  const teamMembers = ["Shinegerel", "Azbayar", "Otgonjargal", "Altantsetseg", "Narantsetseg", "Hulan", "Bilguuntugs", "Otgon", "Guljan", "Tsolmon"];

  //
  // Start: Preload
  //
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    if (window.location.pathname === "/") {
      // Check if it's the home page
      const preLoadImages = () => {
        let loadedCount = 0;
        console.log("preLoadImages");

        const loadImage = (url) => {
          const img = new Image();
          img.src = url;

          img.onload = () => {
            loadedCount++;
            setLoadedImages(loadedCount);
            console.log(loadedCount);
          };
        };

        imageUrls.forEach((url) => {
          loadImage(url);
        });
      };

      preLoadImages();
    } else {
      setLoadedImages(totalImages);
    }
  }, []);
  //
  // End: Preload
  //

  return (
    <>
      {loadedImages === totalImages ? (
        <Router>
          <AnimatePresence>
            <motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 0 }} transition={{ duration: 0.5 }}>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/services" element={<ServicesLandingPage />} />
                <Route path="/services/consulting" element={<ContentConsulting />} />
                <Route path="/services/advocacy" element={<ContentAdvocacy />} />
                <Route path="/services/research" element={<ContentResearch />} />
                <Route path="/services/management" element={<ContentManagement />} />
                <Route path="/join" element={<JoinUsPage />} />
                <Route path="/contact" element={<ContactPage />} />

                {teamMembers.map((member) => (
                  <Route key={member} path={`/${member}`} element={<TeamMember />} />
                ))}

                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </motion.div>
          </AnimatePresence>
        </Router>
      ) : (
        <div className="flex flex-col w-full h-full justify-center items-center fixed">
          <div className="absolute top-0 left-0 w-full h-full opacity-85 z-10"></div>

          <img src="../images/logo.png" alt="BLF" className="w-[90px] relative z-20" style={{ margin: "0 auto" }} />
          <div className="progressWrapper">
            <div className="progressBar" style={{ width: `${(loadedImages / totalImages) * 100}%` }}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
