// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    //
    // 🇺🇸 English
    //

    en: {
      translation: {
        language: "MN",
        languageName: "Монгол",

        fadedText1: "Success",
        fadedText2: "is a",
        fadedText3: "team",
        fadedText4: "effort",

        fadedTextSlogan1: "Legal",
        fadedTextSlogan2: "solutions",
        fadedTextSlogan3: "for business",

        slogan: "LEGAL ADVISORY SERVICES",
        slogan1: "LEGAL ADVICE",
        slogan2: "ADVOCACY ASSISTANCE",
        slogan3: "COURSE RESEARCH",
        slogan4: "CONSULTING",

        sectionTitle2: "OUR ASPIRATION",
        sectionTitle3: "OUR VALUES",

        whyUsDesc:
          "Our law firm is dedicated to safeguarding the rights and legal interests of our clients, fostering their business growth, and adeptly resolving their challenges. With over a decade of experience, our seasoned team has tackled diverse legal matters encompassing business disputes, labor conflicts, criminal proceedings, civil litigation, and administrative matters. We excel in corporate governance, banking and finance, capital markets, real estate transactions, domestic and international projects. Throughout our journey, we have earned the trust of our clients and consistently delivered successful outcomes.",
        whyUsDesc2: "We pride ourselves on establishing long-term relationships with our customers and clients, and not just focusing on results.",
        customCardTitle1: "PROSPICIENCE",
        customCardDesc1:
          "We aspire to stand as the preeminent business law firm within our industry, dedicated to delivering unparalleled quality and value to our esteemed clients.",
        customCardTitle2: "MISSION",
        customCardDesc2:
          "We are committed to delivering superior, expeditious, and innovative legal services by thoroughly comprehending the intricacies of the business sector and the unique needs of our clients.",
        customCardTitle3: "RESPONSIBILITY ETHICS",
        customCardDesc3:
          "We uphold a stringent commitment to elevated ethical standards in all aspects of our business, relationships, and decision-making processes.",
        customCardTitle4: "INNOVATION",
        customCardDesc4:
          "We are dedicated to perpetually challenging ourselves, fostering a culture of continuous learning, and actively seeking improvement to pioneer modern and creative solutions.",
        customCardTitle5: "TEAM",
        customCardDesc5:
          "We are dedicated to cultivating a positive environment and actively contributing to the well-being of our team, family, customers, and society at large.",
        ourSeriviceTitle: "OUR SERVICE",
        ourSeriviceDesc1:
          "Established in 2016, our business law firm has been steadfastly delivering professional services, including legal advice, assistance, defense, training, and research to both domestic and foreign citizens, as well as legal entities. Our comprehensive approach is anchored within the legal frameworks of Mongolia and international law.",
        ourSeriviceDesc2:
          "To forge a competitive advantage for our customers and clients, we remain vigilant to changes in the business and legal landscape, ensuring timely adaptations. Our commitment extends to issuing precise legal rulings that align with the rights and legal interests of our clients.",
        featureTitle1: "LEGAL ADVISORY SERVICES",
        featureTitle2: "ADVOCACY AND LEGAL AID",
        featureTitle3: "COURSE RESEARCH",
        ourServiceTitle1: "LEGAL ADVISORY SERVICES",
        ourServiceDesc1:
          "We specialize in tailoring our services to specific business areas and industry nuances, offering professional legal consulting services designed to address the unique challenges of our clients and mitigate risks effectively.",
        ourServiceDesc1Helper: "In this",
        ourServiceDesc1List1: "Business Law and Corporate Governance",
        ourServiceDesc1List2: "Taxes and insurance",
        ourServiceDesc1List3: "Securities and Stock Market",
        ourServiceDesc1List4: "Banking, finance and financing",
        ourServiceDesc1List5: "Building, energy and infrastructure",
        ourServiceDesc1List6: "Trade, business and foreign investment",
        ourServiceDesc1List7: "Intellectual property, copyright and patent",
        ourServiceDesc1List8: "Mining and natural resources",
        ourServiceDesc1List9: "Examples include real estate and asset management.",
        ourServiceTitle2: "TYPES OF CONSULTING SERVICES",
        ourServiceDesc2_1:
          "Business Advisory Services: Offering legal counsel on the regulatory framework of business activities, providing recommendations on the structure and organization of collaborative ventures in business activities.",
        ourServiceDesc2_2:
          "Comprehensive Corporate Services: Offering guidance on joint venture establishment, share sale, purchase, and restructuring. Drafting and registering documents and contracts, providing counsel on the day-to-day business activities of the company, and serving as a trusted corporate secretary.",
        ourServiceDesc2_3:
          "Proficient Contract Management: We specialize in the drafting and meticulous monitoring of large-scale contracts and agreements. Our team excels in preparing business and trade contracts, ensuring they meet high professional standards aligned with international norms.",
        ourServiceDesc2_4:
          "Legal Due Diligence and Reporting: We conduct thorough legal due diligence, reviewing the company's legal compliance. Our team prepares independent reports tailored for investors involved in buying or selling shares.",
        ourServiceDesc2_5:
          "Legal Opinion Crafting: We formulate legal opinions by meticulously analyzing the operations of the borrower and the company seeking capital for investment. Our expertise extends to navigating financing negotiations and the securities issuance process.",
        ourServiceDesc3_1:
          "We offer a range of legal services, including representing clients in civil and administrative proceedings, criminal and misdemeanor proceedings, arbitration proceedings, and enforcement proceedings. In response to the needs and requirements of our customers, we also conduct legal environment research and organize courses on legal and organizational development.",
        ourServiceDesc3_2:
          "Our team brings extensive experience, having organized training and development activities for over 300,000 citizens in the capital city of Ulaanbaatar and 21 provinces, encompassing more than 100 sums and settlements across Mongolia. Our reach extends to government agencies of the Mongolian University (MU), state and local organizations, business group companies, Top Enterprises of MU, Mongolia, and OU's non-governmental organizations, totaling approximately 1,000 organizations.",
        ourTeamTitle: "OUR TEAM",
        ourTeamDesc1:
          "Our team comprises attorneys with profound expertise in Mongolia's legislation, legal system, business environment, and political relations. Additionally, we have specialists in taxation, corporate governance, and the securities market, all bringing extensive knowledge and experience to meet the diverse needs of our clients.",
        ourTeamDesc2:
          "Our consultants are seasoned lawyers with 20-30 years of experience, having worked in both courts and prosecutor's offices. Their extensive tenure in legal practice equips them with a wealth of knowledge and a deep understanding of various legal intricacies.",

        education: "EDUCATION",
        membership: "MEMBERSHIP",
        workxp: "EXPERIENCE",
        skills: "PROFICIENCY",
        languageSkills: "LANGUAGE",
        proficiency: "PROFESSION",

        ourStatTitle: "OUR INFLUENCE",
        ourStat1: "LEGAL AID PROVIDED",
        ourStat2: "PARTNER INSTITUTIONS",
        ourStat3: "LITIGATION",
        ourStat4: "TEACHING AND RESEARCH",

        testimonials1Name: "B. NARANBAATAR",
        testimonials1Title: "General Director of Hanbogd Khurd LLC",
        testimonials1Desc:
          "We never imagined that the intricacies of the law could be taught in such an engaging and comprehensible manner. We extend our gratitude for imparting essential knowledge about Labor Law and Safety to our employees and for thoroughly addressing their questions. We are delighted to continue our collaboration with you.",

        testimonials2Name: "J. BATTULGA",
        testimonials2Title: "Director of Galba Station LLC",
        testimonials2Desc:
          "We operate as a supplier for Oyu-Tolgoi LLC, and we sincerely appreciate your assistance in developing the necessary legal documents, paperwork, and regulations required by Oyu Tolgoi Ltd. Thank you for your invaluable support.",

        testimonials3Name: "S. OTGONBAT",
        testimonials3Title: "General Director of Micro Globe Group",
        testimonials3Desc:
          'The saying, "If you want to be a leader, work with leaders," holds true. Collaborating with professionals not only saves time but also proves cost-effective. We appreciate your trust in our services, and thank you for allowing us to examine your business and provide prompt and responsible legal advice and assistance.',

        partnersTitle: "PARTNER INSTITUTIONS",

        contatctUsTitle: "PLEASE CONTACT US",
        loading: "Loading...",
        inputName: "Name",
        inputPhone: "Phone",
        inputEmail: "Email address",
        inputMessage: "Message",
        inputSubmit: "Send",
        inputSuccessMessage: "Your form has been successfully submitted. We will contact you soon.",

        addressTitle: "ADDRESS",
        addressFull: "Mongolia, Ulaanbaatar City, Sukhbaatar District 1st District, Peace Avenue, Blue Sky Tower, 11th floor",
        address1: "Mongolia, Ulaanbaatar City",
        address2: "Sukhbaatar 1st District, Peace Avenue",
        address3: "Blue Sky Tower, 11th floor",

        // ШИНЭГЭРЭЛ
        teamShinegerelFname: "SHINEGEREL",
        teamShinegerelLname: "Amarsanaa",
        teamShinegerelTitle: "Executive Director",
        teamShinegerelDesc1:
          "A.Shinegerel started working in his profession in 2012 and started working as a lawyer and advocate of the partnership in 2022. In addition to working as a lawyer during the above period, as a result of his successful work in charge of business development, external relations and cooperation development of the firm, she has been successfully promoted to partner and executive management of the partnership.",
        teamShinegerelDesc2:
          "She has specialized experience in Investments, Real Estate, Securities Market, Criminal Law, Land Disputes, Family - Inheritance and Contract Law.",
        teamShinegerelDesc3:
          "She has also successfully managed legal framework research and legal opinion projects and accumulated considerable experience.",
        teamShinegerelWorkxp1: "",
        teamShinegerelLanguageSkills1: "",
        teamShinegerelEducation1: "",
        teamShinegerelProficiency1: "Lawyer",
        teamShinegerelProficiency2: "Translator",
        teamShinegerelSkill1:
          "Lawyer with the right to “provide legal advisory services to participants in the securities market“ - Financial Regulatory Commission of Mongolia",
        teamShinegerelSkill2: "",
        teamShinegerelMembership1: "Member of the “Mongolian Lawyers' Association“.",
        teamShinegerelMembership2: "Member of the “Mongolian Advocacy' Association“.",
        teamShinegerelMembership3: "Member of the “International Bar Association“.",

        // ГҮЛЖАН
        teamGuljanFname: "Gulzhan",
        teamGuljanLname: "Sherhan",
        teamGuljanTitle: "Lawyer",
        teamGuljanDesc1:
          "Sh. Gulzhan is one of the experienced lawyers responsible for our firm's foreign relations, contracts, insurance, and labor law.",
        teamGuljanDesc2:
          "Since she first started his career as a lawyer at “People's Insurance“ LLC, she has been working mainly in Mining, Financial Insurance, Securities, Investment, and Foreign Trade, and has accumulated knowledge and experience.",
        teamGuljanDesc3:
          "To date, we have successfully managed more than 50 business negotiations in English and Turkish, as well as protecting the legal interests and interests of our clients in large-scale contracts with high prices.",
        teamGuljanWorkxp1: "",
        teamGuljanLanguageSkills1: "",
        teamGuljanEducation1: "Faculty of Law, Gazi University, Turkey, BA",
        teamGuljanEducation2: "M.Sc. School of Law, MSU",
        teamGuljanEducation3: "University of Humanities, English Translator, B.Sc",
        teamGuljanProficiency1: "Lawyer",
        teamGuljanProficiency2: "Translator",
        teamGuljanSkill1:
          "A lawyer with the right to “provide legal advisory services to participants in the securities market“ of the Financial Regulatory Commission of Mongolia",
        teamGuljanMembership1: "Member of the “Mongolian Lawyers' Association“.",

        // БИЛГҮҮНТӨГС
        teamBilguuntugsFname: "Bilguuntugs",
        teamBilguuntugsLname: "Davaasuren",
        teamBilguuntugsTitle: "Assistant Attorney",
        teamBilguuntugsDesc1:
          "D. Bilguntogs provides technical and practical assistance in the day-to-day activities of the association's defense and court cases, and prepares and analyzes the necessary facts and data, and prepares documents.",
        teamBilguuntugsDesc2:
          "The employee is a skilled professional with good productivity and performance, and can be a good specialist who has earned the trust of the lawyers and attorneys of his firm.",
        teamBilguuntugsWorkxp1: "Assistant attorney at “BUSINESS LAW FIRM“ LLC",
        teamBilguuntugsLanguageSkills1: "Korean",
        teamBilguuntugsEducation1: "Bachelor of Law at the School of Law and Social Sciences of the University of Internal Affairs",
        teamBilguuntugsEducation2: "Bachelor of Police Management at Changshin University, South Korea",
        teamBilguuntugsProficiency1: "",
        teamBilguuntugsSkill1: "",
        teamBilguuntugsMembership1: "",

        // ОТГОН
        teamOtgonFname: "Otgon",
        teamOtgonLname: "Batjargal",
        teamOtgonTitle: "General manager",
        teamOtgonDesc1:
          "B. Otgon not only ensures the organization of the law firm's daily operations but also provides technical and practical assistance in the firm's activities and legal disputes. They have strong skills in preparing and analyzing necessary documents and research, as well as drafting legal documents.",
        teamOtgonDesc2:
          "This employee is a highly skilled professional with excellent productivity and performance, earning the trust of the firm's lawyers and attorneys.",
        teamOtgonWorkxp1: "Executive Director – “GOLDEN STIRRUP MINING MACHINERY” LLC (April 2024 – July 2024)",
        teamOtgonWorkxp2: "Executive Director – “GOLDEN SPRING INTERNATIONAL” LLC (January 2024 – July 2024)",
        teamOtgonWorkxp3: "Human Resources Manager – “GOLDEN SPRING INTERNATIONAL” LLC (September 2022 – January 2024)",
        teamOtgonLanguageSkills1: "English",
        teamOtgonLanguageSkills2: "Chinese",
        teamOtgonEducation1: "Bachelor’s Degree in Business Administration – Ming Dao University, Taiwan",
        teamOtgonEducation2: "Bachelor’s Degree in Law – School of Law, National University of Mongolia",
        teamOtgonProficiency1: "",
        teamOtgonSkill1: "",
        teamOtgonMembership1: "",

        // ОТГОНЖАРГАЛ
        teamOtgonjargalFname: "Otgonjargal",
        teamOtgonjargalLname: "Sumya",
        teamOtgonjargalTitle: "Partner, Lawyer",
        teamOtgonjargalDesc1: "S. Otgonjargal has been working as a partner and attorney at the law firm since 2024.",
        teamOtgonjargalDesc2:
          "Since 2006, he has been a member of the Mongolian Bar Association and has been working as a lawyer for 18 years. He is a recognized and qualified lawyer with extensive experience in foreign citizenship matters.",
        teamOtgonjargalDesc3:
          "S. Otgonjargal partner adheres to the rules of professional ethics, based on the demands and needs of citizens and legal entities, provides optimal legal advice in accordance with laws and regulations, and provides legal assistance and advocacy services.",
        teamOtgonjargalWorkxp1: "",
        teamOtgonjargalLanguageSkills1: "English",
        teamOtgonjargalLanguageSkills2: "Japanese",
        teamOtgonjargalEducation1: "Ikh Zasag University, B.Sc",
        teamOtgonjargalEducation2: "Japan University of Economics, M.Sc",
        teamOtgonjargalEducation3: "Academy of Management - Department of Public Administration",
        teamOtgonjargalEducation4: "Gunma University",
        teamOtgonjargalEducation5: "Chuo University Law School",
        teamOtgonjargalProficiency1: "Lawyer",
        teamOtgonjargalProficiency2: "Public administration manager",
        teamOtgonjargalProficiency3: "Business management / master /",
        teamOtgonjargalProficiency4: "Japanese translator",
        teamOtgonjargalSkill1: "",
        teamOtgonjargalMembership1: "",

        // АЗБАЯР
        teamAzbayarFname: "Azbayar",
        teamAzbayarLname: "Tseepel",
        teamAzbayarTitle: "Founder, Partner",
        teamAzbayarDesc1:
          "Ts. Azbayar is a partner who founded the company in 2016 and has successfully led the firm's business consulting, legal training and research team.",
        teamAzbayarDesc2:
          "He has experience working in the Secretariat of the State Congress of MU and the Secretariat of Government Affairs. He has worked in the business sector for more than 10 years. providing professional consulting services in the field of planning and marketing management.",
        teamAzbayarDesc3:
          "He works in the fields of banking and finance, securities and capital markets, mining, business law and corporate governance, intellectual property, labor and contract law.",
        teamAzbayarDesc4:
          "He has experience in providing legal services, such as participating as a client's representative and attorney in civil and administrative proceedings, representing clients in arbitration proceedings, and participating as a client's representative in court decision enforcement proceedings.",
        teamAzbayarWorkxp1: "",
        teamAzbayarLanguageSkills1: "",
        teamAzbayarEducation1: "",
        teamAzbayarProficiency1: "Lawyer / Master /",
        teamAzbayarProficiency2: "Business management /Doctoral student/",
        teamAzbayarSkill1:
          "Lawyer with the right to “provide legal advisory services to participants in the securities market“ - Financial Regulatory Commission of Mongolia",
        teamAzbayarSkill2: "Certified Tax Lawyer – Association of Certified Tax Consultants, ITC",
        teamAzbayarSkill3: "Corporate Governance - National Council on Corporate Governance",
        teamAzbayarMembership1: "Member of the “Mongolian Lawyers' Association“, lawyer and trainer",
        teamAzbayarMembership2: "Member of the “Mongolian Advocacy' Association“.",
        teamAzbayarMembership3: "Member of the “International Bar Association“.",

        // АЛТАНЦЭЦЭГ
        teamAltantsetsegFname: "Altantsetseg",
        teamAltantsetsegLname: "Luvsan-Osor",
        teamAltantsetsegTitle: "Senior Advisor",
        teamAltantsetsegDesc1:
          "L. Altantsetse was awarded the title of Honored Lawyer of MU by the government in recognition of his many years of fruitful work and valuable contribution to the legal field of MU, and it is the pride of our community.",
        teamAltantsetsegDesc2: "She:",
        teamAltantsetsegDesc3: "• Since 1986, Khentii province prosecutor",
        teamAltantsetsegDesc4: "• Since 1998, supervising prosecutor at the General Prosecutor's Office",
        teamAltantsetsegDesc5:
          "• From 2001 to 2003, she was a supervising prosecutor at the Metropolitan Prosecutor's Office with a total of 17 years of experience as a prosecutor.",
        teamAltantsetsegDesc6: "• Worked in the Ministry of Foreign Affairs from 2004 to 2024.",
        teamAltantsetsegDesc7:
          "She serves as the firm's senior criminal counsel, advising on criminal and administrative litigation and enforcement.",
        teamAltantsetsegWorkxp1: "",
        teamAltantsetsegLanguageSkills1: "",
        teamAltantsetsegEducation1: "School of Law, University of Mongolia",
        teamAltantsetsegEducation2: "After graduating from the Academy of Management,",
        teamAltantsetsegEducation3: "Advanced professional training in Russia, Taiwan, USA, ASEAN and China.",
        teamAltantsetsegProficiency1: "Lawyer / Master /",
        teamAltantsetsegProficiency2: "State management",
        teamAltantsetsegSkill1: "",
        teamAltantsetsegMembership1: "",

        // НАРАНЦЭЦЭГ
        teamNarantsetsegFname: "Narantsetseg",
        teamNarantsetsegLname: "Myangaa",
        teamNarantsetsegTitle: "Senior Advisor",
        teamNarantsetsegDesc1: "She:",
        teamNarantsetsegDesc2: "• Worked as a judge in the Selenge District Court and the Metropolitan Court of Appeal for Civil Cases for 38 years.",
        teamNarantsetsegDesc3: "• Since 2000, she has been teaching judges and lawyers in the field of civil law and civil law.",
        teamNarantsetsegDesc4: "She serves as the firm's senior civil counsel, advising on civil litigation, arbitration, and enforcement.",
        teamNarantsetsegWorkxp1: "",
        teamNarantsetsegLanguageSkills1: "",
        teamNarantsetsegEducation1: "School of Law, University of Mongolia",
        teamNarantsetsegProficiency1: "Lawyer / Master /",
        teamNarantsetsegSkill1: "",
        teamNarantsetsegMembership1: "Member of the “Mongolian Lawyers' Association“, lawyer and trainer",

        // ЦОЛМОН
        teamTsolmonFname: "Tsolmon",
        teamTsolmonLname: "Odbaatar",
        teamTsolmonTitle: "Contract Specialist",
        teamTsolmonDesc1:
          "O. Tsolmon started his career as a legal researcher at “Legas“ LLC and has been working as a contract specialist and legal consultant in the construction industry.",
        teamTsolmonDesc2:
          "Responsible for the development of contract documents in the law firm's consulting services, and serves as a legal assistant to the defense team in Labor Relations, Corporate Governance, and Intellectual Property disputes.",
        teamTsolmonWorkxp1: "",
        teamTsolmonLanguageSkills1: "English",
        teamTsolmonEducation1: "Bachelor of the Ikh Zasag University",
        teamTsolmonProficiency1: "Attorney",
        teamTsolmonSkill1: "",
        teamTsolmonMembership1: "",

        // ХУЛАН
        teamHulanFname: "Hulan",
        teamHulanLname: "Otgonbayar",
        teamHulanTitle: "Accountant",
        teamHulanDesc1: "O. Hulan is responsible for the day-to-day financial calculations and accounting of the law firm.",
        teamHulanDesc2:
          "She started his career as a state tax inspector at the General Tax Department. In addition to our work, we also provide financial and accounting outsourcing services.",
        teamHulanDesc3: "She is a responsible, proactive and self-motivated professional.",
        teamHulanWorkxp1: "State Inspector of Taxes at the General Tax Office",
        teamHulanWorkxp2: "Accountant of “BUSINESS LAW FIRM“ LLC",
        teamHulanLanguageSkills1: "",
        teamHulanEducation1: "",
        teamHulanProficiency1: "Accounting",
        teamHulanSkill1: "",
        teamHulanMembership1: "",

        //
        contactUsNow: "Contact Us Now",
        followUs: "Follow Us:",
        pageAbout: "About Us",
        pageServices: "Services",
        pageTeam: "Our Team",
        pageJoin: "Join Us",
        pageContact: "Contact",

        //
        goBackToHome: "Go back to home page",
        moreInfo: "More",
        getAquote: "Get A Quote",
        callNow: "Call Now",
        notFound: "PAGE NOT FOUND",
        notFoundMsg: "The 404 page appears when a requested web page is not found, signaling that the content is unavailable.",

        //
        bestLawyers: "Experience is value",
        homeSection2Title: "WHAT MAKES US DIFFERENT?",
        homeSection1Desc: "",
        homeSection2Desc:
          "Our firm consists of a team of multi-skilled lawyers, attorneys, business and management consultants, trainers and skilled professionals who understand that our clients trust us to achieve good results in a timely manner.",
        homeSection3Desc: "",

        // new!
        ourExperience: "OUR EXPERIENCE",
        customerReview: "Customer reviews",
        historyTitle: "Our story",
        historyDesc1:
          "We embarked on our journey with boldness, aiming to establish a business law firm that is specialized, adaptable, and inclusive. Our aspiration is to not only foster the growth of individuals and teams but also to make significant contributions to our industry, our nation, and to carve out our own distinctive legacy.",
        historyDesc2:
          "Hence, our aim is not merely to establish a law firm, but to cultivate an environment where our team finds fulfillment in their work, fosters a congenial atmosphere, and collaborates seamlessly to advance and enhance our clients' businesses.",
        historyDesc3:
          "As our activities and reputation continue to expand, we remain steadfast in our commitment to creating enduring value and a positive reputation within our society.",

        services1Title: "Legal consulting services",
        services2Title: "Advocacy",
        services3Title: "Teaching & Research",
        services4Title: "Management consulting services",

        experience: "Experience",
        expereinceDesc: "Our field of work",

        xpTitle1: "Corporate governance",
        xpTitle2: "Capital markets and securities (such as IPOs and secondary offerings)",
        xpTitle3: "Investments /individual and joint/",
        xpTitle4: "Capital financing",
        xpTitle5: "Asset management",
        xpTitle6: "Business restructuring and reorganization",
        xpTitle7: "Mergers and acquisitions",
        xpTitle8: "banking and finance",
        xpTitle9: "Mining and energy",
        xpTitle10: "Buildings, infrastructure,",
        xpTitle11: "Land and redevelopment",
        xpTitle12: "Mineral exploration and production",
        xpTitle13: "Civil service and administrative disputes",
        xpTitle14: "Tax",
        xpTitle15: "Tender selection",
        xpTitle16: "Real estate",
        xpTitle17: "Immigration",
        xpTitle18: "Intellectual property",
        xpTitle19: "Consumer Rights and Competition",
        xpTitle20: "Commercial and contractual disputes",
        xpTitle21: "Labor disputes",
        xpTitle22: "Licensing and Permitting",

        fields: "Fields",
        fieldOfStudy: "Field of study",

        contentAdvocacyDesc1: "Our objective is to resolve disputes with intelligence, efficiency, and cost-effectiveness as our guiding principles.",
        contentAdvocacyDesc2:
          "With years of experience, our legal team excels in resolving and prevailing in a diverse array of complex legal matters.",
        contentAdvocacyDesc3:
          "We formulate negotiation strategies tailored to resolving disputes in alignment with the rights and legitimate interests of our clients.",
        contentAdvocacyDesc4:
          "Our attorneys possess the expertise to conduct thorough research, draft legislation, and adeptly represent you throughout all stages of pre-trial and court proceedings.",
        contentAdvocacyDesc5:
          "In litigation, our approach is centered on a comprehensive view of the case, strategizing for optimal outcomes, and meticulous attention to detail. We dedicate ourselves fully to maximizing every opportunity, ensuring that no detail is overlooked in pursuit of our clients' objectives.",
        contentAdvocacyDesc6:
          "At the heart of our philosophy lies the belief that our lawyers and advocates function most effectively when operating as cohesive teams. By leveraging diverse perspectives, engaging in healthy competition, and fostering collaboration, we cultivate an environment conducive to delivering innovative and creative solutions to our clients' challenges.",

        advocacyFieldsTitle1: "Land dispute",
        advocacyFieldsTitle2: "Contract disputes",
        advocacyFieldsTitle3: "Disputes between partnership shareholders",
        advocacyFieldsTitle4: "Real estate",
        advocacyFieldsTitle5: "Re-planning",
        advocacyFieldsTitle6: "Inheritance,",
        advocacyFieldsTitle7: "Family",
        advocacyFieldsTitle8: "Labor disputes",
        advocacyFieldsTitle9: "Intellectual property",
        advocacyFieldsTitle10: "Civil service",
        advocacyFieldsTitle11: "Election",
        advocacyFieldsTitle12: "Customs and taxes",
        advocacyFieldsTitle13: "Tender selection",
        advocacyFieldsTitle14: "Permits and state registration",
        advocacyFieldsTitle15: "Company is a legal entity",
        advocacyFieldsTitle16: "Tort Non-contractual obligations",
        advocacyFieldsTitle17: "Foreign citizen",
        advocacyFieldsTitle18: "Criminal offense",
        advocacyFieldsTitle19: "Litigation",
        advocacyFieldsTitle20: "Arbitration Disputes",

        contentConsultingDesc1:
          "Drawing upon our extensive legal knowledge, honed skills, practical experience, and refined methodology, BLF is dedicated to furnishing our clients with pivotal information and expert advice. Our aim is to empower them to navigate both their day-to-day operations and significant business decisions with professionalism and confidence.",
        contentConsultingDesc2:
          "Our team of lawyers and advocates is committed to delivering swift, efficient, and accessible services. We prioritize a deep understanding of the challenges inherent in today's rapidly evolving social, economic, and business landscape, ensuring that our clients receive informed and comprehensive support.",
        contentConsultingDesc3:
          "We distinguish ourselves by offering consulting services that integrate legal expertise with business management acumen. Our approach places a heightened emphasis on understanding the unique needs and requirements of each client, as well as their overarching vision and policy objectives.",
        contentConsultingDesc4:
          "Recognizing the unique nature of each client's business and its distinct characteristics, we meticulously explore all potential avenues. Upholding our clients' goals, legal rights, and interests within the bounds of the law, we prioritize a diligent approach, marked by persistence, innovation, and thorough preparation, to attain specific and favorable outcomes.",

        consultingFieldsTitle1: "Mining and minerals",
        consultingFieldsTitle2: "Construction, Energy and Infrastructure",
        consultingFieldsTitle3: "Banking and finance",
        consultingFieldsTitle4: "Securities investment",
        consultingFieldsTitle5: "Taxes and insurance",
        consultingFieldsTitle6: "Real estate and registration",
        consultingFieldsTitle7: "Intellectual property",
        consultingFieldsTitle8: "Foreign investment",
        consultingFieldsTitle9: "Corporate governance",

        contentResearchDesc1:
          "Our legal courses provide participants with an interactive learning experience, blending theoretical knowledge with practical application. What sets us apart is our customized approach, where we tailor course content to address the specific needs and requirements of our clients.",
        contentResearchDesc2:
          "We adopt a practical, hands-on approach rooted in real-world cases, enabling you to readily apply this knowledge directly to your business operations.",

        researchFieldsTitle1: "Contract law",
        researchFieldsTitle2: "Labor law",
        researchFieldsTitle3: "Tax law",
        researchFieldsTitle4: "Copy right",
        researchFieldsTitle5: "Corporate governance – law",
        researchFieldsTitle6: "Business and human rights",

        contentManagementDesc1Title: "1. POLITICAL MANAGEMENT",
        contentManagementDesc2Title: "2. BUSINESS MANAGEMENT",
        contentManagementDesc1:
          "Political management is a multifaceted concept that encompasses various aspects of professional political engagement. It is a dynamic field that continually evolves and undergoes development.",
        contentManagementDesc2:
          "This domain encompasses a spectrum of activities ranging from campaign management and strategic planning to political research, public relations tactics, and legal regulation.",
        contentManagementDesc3:
          "Business management consulting services entail external consulting aimed at enhancing organizational performance, boosting productivity, facilitating change management, and ultimately realizing organizational objectives.",
        contentManagementDesc4:
          "We specialize in conducting diagnostics to pinpoint issues within business processes, and then provide tailored solutions aimed at implementing technology and optimal systems. Our goal is to enhance efficiency and effectiveness in the workplace.",

        managementFieldsTitle: "Political management:",
        managementFieldsTitle2: "Business Management:",

        managementFields1: "Election campaign management",
        managementFields2: "Strategic planning",
        managementFields3: "Political branding and message processing",
        managementFields4: "Technology of political PR",
        managementFields5: "Drafting of laws",
        managementFields6: "Making a legal opinion",

        managementFields2_1: "Corporate governance",
        managementFields2_2: "Group company management",
        managementFields2_3: "Business strategy",
        managementFields2_4: "Provides marketing management consulting services",

        joinUsTitle: "JOIN US",
        joinUsSlogan: "The backbone of a law firm lies in its people.",
        joinUsDesc1:
          "At BLF, our dedication to excellence permeates every aspect of our work. Our team comprises talented, diverse, creative, and diligent individuals who consistently strive to make a positive impact on our clients and communities alike.",
        joinUsDesc2:
          "We are a dynamic and expanding law firm, actively seeking talented lawyers at all stages of their careers. Our firm offers an exceptional environment for professional growth, where individuals can thrive and tackle challenges in a fast-paced, stimulating setting.",
        joinUsDesc3:
          "We are actively seeking lawyers and attorneys to join our team, and our selection process prioritizes individuals who align with our company's rich heritage and culture. We value team players who demonstrate a strong work ethic, share our enthusiasm for achieving common objectives, and contribute positively to our dynamic work environment. Ideal candidates exhibit qualities of interest, energy, and ambition, while also maintaining a healthy work-life balance.",
        joinUsDesc4:
          "If you resonate with our core principles and aspire to join our esteemed team, we encourage you to reach out to us at contact@businesslawfirm.mn.",
      },
    },

    //
    // 🇲🇳 Mongol
    //

    mn: {
      translation: {
        language: "EN",
        languageName: "English",

        fadedText1: "Амжилт",
        fadedText2: "бол",
        fadedText3: "багийн",
        fadedText4: "бүтээл",

        fadedTextSlogan1: "Бизнест",
        fadedTextSlogan2: "хуулийн",
        fadedTextSlogan3: "шийдэл",

        slogan: "ХУУЛЬ ЗҮЙН ЗӨВЛӨХ ҮЙЛЧИЛГЭЭ",
        slogan1: "Хуулийн зөвлөх үйлчилгээ",
        slogan2: "Өмгөөлөл",
        slogan3: "Сургалт Судалгаа",
        slogan4: "Менежментийн зөвлөх үйлчилгээ",

        sectionTitle2: "БИДНИЙ ТЭМҮҮЛЭЛ",
        sectionTitle3: "ҮНЭТ ЗҮЙЛС",

        whyUsDesc:
          "Бид үйлчлүүлэгчдийнхээ эрх, хууль ёсны ашиг сонирхолыг хамгаалах, бизнесийн хөгжлийг нь дэмжиж, тулгарсан асуудлыг нь үр дүнтэй шийдвэрлэхийн төлөө ажилладаг. Манай хамт олон 10 гаруй жилийн хугацаанд бизнесийн маргаан, хөдөлмөрийн маргаан, эрүүгийн, иргэний болон захиргааны маргаан, компанийн засаглал, банк санхүү, хөрөнгийн зах зээл, үл хөдлөх эд хөрөнгө, ОУ-ын болон дотоодын төсөл зэрэг олон төрлийн хууль эрх зүйн асуудлууд дээр ажиллаж, үйлчлүүлэгчийнхээ итгэлийг хадгалж, амжилттай ажиллаж ирсэн.",
        whyUsDesc2:
          "Бид зөвхөн ажлын үр дүнд төвлөрөх бус, харилцагч, үйлчлүүлэгч нартайгаа урт хугацааны харилцаа холбоог тогтоож, тогтвортой хамтран ажилладагаараа бахархдаг.",
        customCardTitle1: "АЛСЫН ХАРАА",
        customCardDesc1: "Бид үйлчлүүлэгчиддээ чанар, үнэ цэнийг хүргэж, салбартаа тэргүүлэгч бизнесийн хуулийн фирм байхыг эрмэлзэнэ.",
        customCardTitle2: "ЭРХЭМ ЗОРИЛГО",
        customCardDesc2:
          "Бидний зорилго бол үйлчлүүлэгчдийнхээ бизнесийн салбар болон хэрэгцээ шаардлагыг нь бүрэн ойлгож, илүү сайн, шуурхай, шинэлэг хууль зүйн үйлчилгээг үзүүлэхэд оршино.",
        customCardTitle3: "ХАРИУЦЛАГА & ЁС ЗҮЙ",
        customCardDesc3: "Бид ажил хэргийн харилцаанд хариуцлагатай хандаж, шийдвэр гаргах үйл явцдаа ёс зүйн хэм хэмжээг өндөр түвшинд баримтална.",
        customCardTitle4: "ИННОВАЦИ",
        customCardDesc4: "Бид орчин үеийн, бүтээлч шийдлийг үргэлж эрэлхийлж, тасралтгүй суралцаж, сайжрахыг эрмэлзэнэ.",
        customCardTitle5: "ХАМТ ОЛОН",
        customCardDesc5: "Бид нийгмийн сайн сайхан, шудрага ёсны төлөө хамртан зүтгэх чадварлаг хамт олныг бүрдүүлж ажиллана.",
        ourSeriviceTitle: "БИДНИЙ ҮЙЛЧИЛГЭЭ",
        ourSeriviceDesc1:
          "BLF нь 2016 онд үүсгэн байгуулагдсан цагаасаа өнөөдрийг хүртэлх хугацаанд дотоодын ба гадаадын иргэн, хуулийн этгээдүүдэд МУ-ын болон ОУ-ын эрх зүйн хүрээнд хууль зүйн болон бизнес менежментийн зөвлөгөө, туслалцаа, сургалт, судалгаа,  консалтингийн үйлчилгээг үзүүлж ирсэн.",
        ourSeriviceDesc2:
          "Бид харилцагч, үйлчлүүлэгч нартаа өрсөлдөөний давуу талыг бий болгохын тулд бизнесийн болон эрх зүйн орчны өөрчлөлтийг цаг тухайд нь мэдэрч, тэдний эрх, хууль ёсны ашиг сонирхолд нийцэх оновчтой зөв шийдлийг санал болгохын төлөө ажилладаг.",
        featureTitle1: "ХУУЛЬ ЗҮЙН ЗӨВЛӨХ ҮЙЛЧИЛГЭЭ",
        featureTitle2: "ӨМГӨӨЛӨЛ, ХУУЛЬ ЗҮЙН ТУСЛАЛЦАА",
        featureTitle3: "СУРГАЛТ СУДАЛГАА",
        ourServiceTitle1: "ХУУЛЬ ЗҮЙН ЗӨВЛӨХ ҮЙЛЧИЛГЭЭ",
        ourServiceDesc1:
          "Бид ААН байгууллагуудад үйл ажиллагааны чиглэл, салбарын онцлогт нь тохирсон үйлчилгээг санал болгож, тулгамдаж буй асуудлыг нь шийдвэрлэх, эрсдлийг бууруулахад чиглэгдсэн хууль зүйн мэргэжлийн консалтингийн үйлчилгээг үзүүлдэг.",
        ourServiceDesc1Helper: "Үүнд",
        ourServiceDesc1List1: "Бизнесийн эрх зүй, компанийн засаглал",
        ourServiceDesc1List2: "Татвар, даатгал",
        ourServiceDesc1List3: "Үнэт цаас, Хөрөнгийн зах зээл",
        ourServiceDesc1List4: "Банк, санхүү, санхүүжилт",
        ourServiceDesc1List5: "Барилга, эрчим хүч, дэд бүтэц",
        ourServiceDesc1List6: "Худалдаа, бизнес, гадаадын хөрөнгө оруулалт",
        ourServiceDesc1List7: "Оюуны өмч, зохиогчийн эрх, патент",
        ourServiceDesc1List8: "Уул уурхай, байгалийн нөөц",
        ourServiceDesc1List9: "Үл хөдлөх хөрөнгө, хөрөнгийн менежментийн салбаруудыг дурьдаж болно.",
        ourServiceTitle2: "КОНСАЛТИНГИЙН ҮЙЛЧИЛГЭЭНИЙ ТӨРЛҮҮД",
        ourServiceDesc2_1:
          "Бизнесийн зөвлөгөө: Бизнесийн үйл ажиллагааны эрх зүйн орчны талаар хууль зүйн зөвлөгөө өгч, бизнесийн үйл ажиллагаанд хамтран ажиллах бүтэц, зохион байгуулалтын талаар гаргалгаа хийх, зөвлөмж өгөх",
        ourServiceDesc2_2:
          "Хамтарсан компани үүсгэн байгуулах, хувьцаа худалдах, худалдан авах, бүтцийн өөрчлөлт хийхтэй холбоотой ажиллагаанд зөвлөж, баримт бичиг, гэрээ хэлцлийн төсөл боловсруулах, бүртгүүлэх, компанийн өдөр тутмын бизнесийн үйл ажиллагаанд зөвлөж, корпорейт нарийн бичгийн даргын үүрэг гүйцэтгэн ажиллах",
        ourServiceDesc2_3:
          "Томоохон хэмжээний гэрээ, хэлцлийн төсөл боловсруулах, хянах: бизнес, худалдааны гэрээ, хэлцлийн төслийг олон улсын жишигт нийцсэн, мэргэжлийн өндөр түвшинд бэлтгэх",
        ourServiceDesc2_4:
          "Хууль зүйн магадлан шинжилгээ (due diligence) хийх, тайлан бэлтгэх: Хувьцаа худалдах худалдан авахаар сонирхож буй хөрөнгө оруулагчдад компанийн хууль зүйн нийцлийг хянан шалгаж, хараат бус тайлан бэлтгэн өгөх",
        ourServiceDesc2_5:
          "Хууль зүйн дүгнэлт гаргах: Хөрөнгө оруулалт, санхүүжилтийн хэлэлцээр, үнэт цаас гаргах ажиллагаанд зээлдэгч, хөрөнгө татан төвлөрүүлэх гэж буй компанийн үйл ажиллагаанд магадлан шинжилгээ хийж хууль зүйн дүгнэлт гаргах",
        ourServiceDesc3_1:
          "Бид иргэний хэрэг шүүхэд хянан шийдвэрлэх болон захиргааны хэрэг хянан шийдвэрлэх ажиллагаанд үйлчлүүлэгчийн төлөөлөгч, өмгөөлөгчөөр оролцох, эрүүгийн хэрэг болон зөрчлийн хэрэг хянан шийдвэрлэх ажиллагаанд үйлчлүүлэгчийн өмгөөлөгчөөр оролцох, арбитрын ажиллагаанд үйлчлүүлэгчийг төлөөлөх, шүүхийн шийдвэр гүйцэтгэх ажиллагаанд үйлчлүүлэгчийн төлөөлөгчөөр оролцох зэрэг хууль зүйн үйлчилгээнүүдийг үзүүлдэг. Бид харилцагч, үйлчлүүлэгчийнхээ хэрэгцээ, шаардлагад тулгуурлан эрх зүйн орчны судалгаа, мөн хууль эрх зүйн болон байгууллагын хөгжлийн сургалтуудыг зохион байгуулдаг.",
        ourServiceDesc3_2:
          "Манай баг Монгол орон даяар Нийслэл Улаанбаатар хот болон 21 аймаг, 100 гаруй сум, суурин газарт 300.000 гаруй иргэд, мөн МУ-ын Засгийн Газрын агентлагууд, төрийн болон орон нутгийн байгууллагууд, Бизнесийн групп компаниуд, МУ-ын Топ ААН-үүд, Монголын болон ОУ-ын төрийн бус байгууллагууд гээд 1000 орчим байгууллагуудад сургалт хөгжлийн үйл ажиллагааг зохион байгуулж ирсэн туршлагатай.",
        ourTeamTitle: "МАНАЙ ХАМТ ОЛОН",
        ourTeamDesc1:
          "Монгол улсын хууль тогтоомж, хууль, эрх зүйн систем, бизнесийн орчин, улс төрийн харилцааны талаар өргөн хүрээний мэдлэг, туршлагатай өмгөөлөгч нар, татвар, компанийн засаглал болон үнэт цаасны зах зээлийн чиглэлээр мэргэшсэн хуульч нараас бүрдсэн баг ажилладаг.",
        ourTeamDesc2:
          "Манай зөвлөхүүд шүүх, прокурорын байгууллагад 20-30 жил ажилласан хууль зүйн практикийн өргөн туршлагатай, мэргэшсэн хуульч нар байдаг.",

        education: "БОЛОВСРОЛ",
        membership: "ГИШҮҮНЧЛЭЛ",
        workxp: "АЖЛЫН ТУРШЛАГА",
        skills: "МЭРГЭШИЛ",
        languageSkills: "ГАДААД ХЭЛ",
        proficiency: "МЭРГЭЖИЛ",

        ourStatTitle: "БИДНИЙ НӨЛӨӨ",
        ourStat1: "ХУУЛЬ ЗҮЙН ТУСЛАЛЦАА ҮЗҮҮЛСЭН",
        ourStat2: "ХАМТРАГЧ БАЙГУУЛЛАГУУД",
        ourStat3: "ШҮҮХИЙН МАРГААН",
        ourStat4: "СУРГАЛТ, СУДАЛДАА",

        testimonials1Name: "Б.НАРАНБААТАР",
        testimonials1Title: "Ханбогд хурд ХХК-ийн Ерөнхий захирал",
        testimonials1Desc:
          "Хуулийг ийм сонирхолтой бас ойлгомжтой зааж болдог гэж бид үнэхээр бодоогүй. Манай ажилтнуудад Хөдөлмөрийн эрх зүй, аюулгүй ажиллагааны талаар мэдвэл зохих ойлголтуудыг өгч, бас тэдний асуултанд бүрэн дүүрэн хариулж өгсөнд баярлалаа. Бид үргэлжлүүлэн хамтран ажиллах болсондоо сэтгэл хангалуун байна.",

        testimonials2Name: "Ж.БАТТУЛГА",
        testimonials2Title: "Галба Өртөө ХХК-ийн захирал",
        testimonials2Desc:
          "Манай компани Оюу-толгой ХХК-ийн ханган нийлүүлэгчээр ажилладаг. Оюу толгой ХХК-аас шаарддаг хууль эрх зүй, ЭМААБО-ны болон хүний нөөцийн баримт, бичиг, дүрэм журмуудыг ОУ-ын стандартын боловсруулж өгдөгт маш их баярлалаа.",

        testimonials3Name: "С.ОТГОНБАТ",
        testimonials3Title: "Бичил Глобус Группийн Ерөнхий захирал",
        testimonials3Desc:
          '"Лидер байя гэвэл лидерүүдтэй хамтар" гэдэгчлэн мэргэжлийн хүнтэй ажиллана гэдэг үнэхээр цаг хугацаа бас мөнгийг хэмнэдэг. Бизнесийг маань хөндлөнгөөс харж, хууль эрх зүйн талаас зөвлөгөө туслалцааг цаг алдалгүй, хариуцлагатай үзүүлдэгт баярлалаа.',

        partnersTitle: "ХАМТРАГЧ БАЙГУУЛЛАГУУД",

        contatctUsTitle: "БИДЭНТЭЙ ХОЛБОГДООРОЙ",
        loading: "Уншиж байна...",
        inputName: "Нэр",
        inputPhone: "Утас",
        inputEmail: "Имайл хаяг",
        inputMessage: "Дэлгэрэнгүй мэдээлэл",
        inputSubmit: "Илгээх",
        inputSuccessMessage: "Таны маягтыг амжилттай илгээлээ. Бид тантай удахгүй холбогдох болно.",

        addressTitle: "ХАЯГ",
        addressFull: "Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг 1-р хороо, Энхтайвны өргөн чөлөө, Blue Sky Tower, 11 давхар",
        address1: "Монгол улс, Улаанбаатар хот",
        address2: "Сүхбаатар дүүрэг 1-р хороо, Энхтайвны өргөн чөлөө",
        address3: "Blue Sky Tower, 11 давхар",

        // ШИНЭГЭРЭЛ
        teamShinegerelFname: "ШИНЭГЭРЭЛ",
        teamShinegerelLname: "Амарсанаагийн",
        teamShinegerelTitle: "Гүйцэтгэх захирал",
        teamShinegerelDesc1:
          "А.Шинэгэрэл нь 2012 оноос хуулийн салбарт ажиллаж байгаа бөгөөд тус фирмийн бизнес хөгжил, гадаад харилцаа, хамтын ажиллагааг хариуцан амжилттай ажилласны үр дүнд нөхөрлөлийн партнер, гүйцэтгэх удирдлагаар дэвшин ажиллаж байна.",
        teamShinegerelDesc2:
          "Тэрээр Хөрөнгө оруулалт, Үл хөдлөх, Үнэт цаас хөрөнгийн зах зээл, Эрүүгийн эрх зүй, Газрын маргаан, Гэр бүл – Өв залгамжлал, Гэрээний эрх зүйн чиглэлээр мэргэшин ажиллахын зэрэгцээ хууль эрх зүйн орчны судалгаа, хууль зүйн дүгнэлт гаргах төслүүдийг удирдаж ажиллаж ирсэн туршлагатай.",
        teamShinegerelDesc3:
          "А.Шинэгэрэл нь мэргэжлийн мэдлэг, ур чадвараа бүрэн дайчилж, хуульч мэргэжлийн үнэ цэнэ, хариуцлага, ёс зүйг эрхэмлэн үйлчлүүлэгчийнхээ эрх, хууль ёсны ашиг сонирхлыг төлөөлж ажилладаг.",
        teamShinegerelWorkxp1: "",
        teamShinegerelLanguageSkills1: "",
        teamShinegerelEducation1: "",
        teamShinegerelProficiency1: "Хуульч, Өмгөөлөгч",
        teamShinegerelProficiency2: "Орчуулагч",
        teamShinegerelSkill1:
          "“Үнэт цаасны зах зээлд оролцогчдод хууль зүйн зөвлөх үйлчилгээ үзүүлэх” эрхтэй хуульч - Монгол Улсын Санхүүгийн Зохицуулах Хороо, МХХ",
        teamShinegerelMembership1: "“Монголын Хуульчдын Холбоо”-ны гишүүн",
        teamShinegerelMembership2: "“Монголын Өмгөөлөгчдийн Холбоо”-ны гишүүн",
        teamShinegerelMembership3: "“Олон Улсын Хуульчдын Холбоо”-ны гишүүн",

        // ГҮЛЖАН
        teamGuljanFname: "ГҮЛЖАН",
        teamGuljanLname: "ШЕРХАНЫ",
        teamGuljanTitle: "Хуульч",
        teamGuljanDesc1:
          "Ш.Гүлжан нь манай фирмийн гадаад харилцаа, гэрээ хэлэлцээр, даатгал, хөдөлмөрийн эрх зүйн чиглэлийг хариуцан ажилладаг туршлагатай хуульчдын нэг юм.",
        teamGuljanDesc2:
          "Тэрээр анх ажлын гараагаа “Ард Даатгал” ХХК-д хуульчаар ажиллаж эхэлснээс хойш Уул уурхай, Санхүү даатгал, Үнэт цаас, Хөрөнгө оруулалт, Гадаад худалдааны салбарт голчлон ажиллаж, мэдлэг чадвар, туршлага хуримтлуулж ирсэн.",
        teamGuljanDesc3:
          "Өнөөдрийг хүртэл Англи, Турк хэл дээр 50 гаруй бизнесийн хэлэлцээрийг амжилттай удирдан хийснээс гадна өндөр үнийн дүн бүхий томоохон хэмжээний гэрээ хэлцлүүдийг хийхэд үйлчлүүлэгчийнхээ хууль ёсны эрх ашиг, сонирхлыг хамгаалан амжилттай ажиллаж байна.",
        teamGuljanWorkxp1: "",
        teamGuljanLanguageSkills1: "",
        teamGuljanEducation1: "Турк улсын Газигийн Их Сургуулийн Хуулийн факультет, Бакалавр",
        teamGuljanEducation2: "МУИС-ийн Хууль Зүйн Сургууль, Магистр",
        teamGuljanEducation3: "Хүмүүнлэгийн ухааны их сургууль, Англи хэлний орчуулагч, Бакалавр",
        teamGuljanProficiency1: "Хуульч",
        teamGuljanProficiency2: "Орчуулагч",
        teamGuljanSkill1:
          "Монгол Улсын Санхүүгийн Зохицуулах Хорооны “Үнэт цаасны зах зээлд оролцогчдод хууль зүйн зөвлөх үйлчилгээ үзүүлэх” эрхтэй хуульч",
        teamGuljanMembership1: "“Монголын Хуульчдын Холбоо”-ны гишүүн",

        // БИЛГҮҮНТӨГС
        teamBilguuntugsFname: "БИЛГҮҮНТӨГС",
        teamBilguuntugsLname: "ДАВААСҮРЭНГИЙН",
        teamBilguuntugsTitle: "Өмгөөлөгчийн туслах",
        teamBilguuntugsDesc1:
          "Д.Билгүүнтөгс нь тус нөхөрлөлийн өмгөөллийн өдөр тутмын үйл ажиллагаа, шүүхийн хэрэг маргаанд техникийн болоод практикийн тусламжийг үзүүлж ажилладаг ба шаардлагатай баримт, мэдээлэл судалгааг боловсруулж дүн шинжилгээ хийх, баримт бичгийг боловсруулж ажилладаг.",
        teamBilguuntugsDesc2:
          "Уг ажилтан нь бүтээмж, гүйцэтгэл сайтай чадварлаг мэргэжилтэн  бөгөөд фирмийнхээ хуульч, өмгөөлөгч нарын итгэлийг хүлээсэн сайн мэргэжилтэн байж чаддаг.",
        teamBilguuntugsWorkxp1: "“BUSINESS LAW FIRM” ХХК-д өмгөөлөгчийн туслах",
        teamBilguuntugsLanguageSkills1: "Солонгос",
        teamBilguuntugsEducation1: "Дотоод Хэргийн Их Сургуулийн “Эрх зүй нийгмийн ухааны сургуульд Эрх зүйч бакалавр",
        teamBilguuntugsEducation2: "БНСУ-ын “Чангшин их сургуульд Цагдаагийн удирдлага бакалавр",
        teamBilguuntugsProficiency1: "",
        teamBilguuntugsSkill1: "",
        teamBilguuntugsMembership1: "",

        // ОТГОН
        teamOtgonFname: "ОТГОН",
        teamOtgonLname: "БАТЖАРГАЛЫН",
        teamOtgonTitle: "Ерөнхий менежер",
        teamOtgonDesc1:
          "Б.Отгон нь хуулийн фирмийн өдөр тутмын үйл ажиллагааг зохион байгуулалтаар хангаж ажилладаг төдийгүй фирмийн үйл ажиллагаа болон шүүхийн хэрэг маргаанд техникийн болоод практикийн тусламжийг үзүүлж ажилладаг. Шаардлагатай баримт, мэдээлэл судалгааг боловсруулж дүн шинжилгээ хийх, баримт бичгийг боловсруулах өндөр чадвартай.",
        teamOtgonDesc2:
          "Уг ажилтан нь бүтээмж, гүйцэтгэл сайтай чадварлаг мэргэжилтэн  бөгөөд фирмийнхээ хуульч, өмгөөлөгч нарын итгэлийг хүлээсэн сайн мэргэжилтэн байж чаддаг.",
        teamOtgonWorkxp1: "“GOLDEN STIRRUP MINING MACHINERY” ХХК - Гүйцэтгэх Захирал /2024.04 – 2024.07/",
        teamOtgonWorkxp2: "“GOLDEN SPRING INTERNATIONAL”  ХХК – Гүйцэтгэх Захирал /2024.01 - 2024.07/",
        teamOtgonWorkxp3: "“GOLDEN SPRING INTERNATIONAL”  ХХК – Хүний Нөөцийн Менежер /2022.09 – 2024.01/",
        teamOtgonLanguageSkills1: "Англи",
        teamOtgonLanguageSkills2: "Хятад",
        teamOtgonEducation1: "Тайваны Минг Дао Их Сургуулийн Бизнес Удирдлагын бакалаврын зэрэг",
        teamOtgonEducation2: "МУИС-ийн Хууль Зүйн сургуульд Эрх зүйч бакалаврын зэрэг",
        teamOtgonProficiency1: "",
        teamOtgonSkill1: "",
        teamOtgonMembership1: "",

        // ОТГОНЖАРГАЛ
        teamOtgonjargalFname: "ОТГОНЖАРГАЛ",
        teamOtgonjargalLname: "СУМЪЯАГИЙН",
        teamOtgonjargalTitle: "Партнер, Өмгөөлөгч",
        teamOtgonjargalDesc1: "С.Отгонжаргал нь 2024 оноос тус хуулийн фирмийн партнер, өмгөөлөгчөөр ажиллаж байна.",
        teamOtgonjargalDesc2:
          "Тэрээр 2006 оноос хойш Монголын Өмгөөлөгчдийн Холбооны гишүүн, өмгөөлөгчөөр 18 жил тасралтгүй ажиллаж байгаа бөгөөд бусдын нэр төр, алдар хүндийг сэргээх, компанийн засаглал, хувьцааны маргаан, хөдлөх болон үл хөдлөх эд хөрөнгийн маргаан, гэрээний харилцаа, өв зөлгөмжлал, гэр бүл, хөдөлмөрийн маргаан, гадаадын иргэн харъяатын асуудлаар арвин  туршлагатай, холбогдох салбартаа хүлээн зөвшөөрөгдсөн, мэргэшсэн өмгөөлөгч юм.",
        teamOtgonjargalDesc3:
          "С.Отгонжаргал партнер нь мэргэжлийн ёс зүйн дүрмийн баримтлан, иргэн, хуулийн этгээдийн эрэлт, хэрэгцээнд үндэслэн, хууль тогтоомжид нийцсэн хууль зүйн оновчтой зөвлөгөөг өгч, эрх зүйн туслалцаа, өмгөөллийн үйлчилгээг үзүүлдэг.",
        teamOtgonjargalWorkxp1: "",
        teamOtgonjargalLanguageSkills1: "Англи",
        teamOtgonjargalLanguageSkills2: "Япон",
        teamOtgonjargalEducation1: "Их засаг их сургууль, Бакалавр",
        teamOtgonjargalEducation2: "Япон Улс, Эдийн засгийн их сургууль, Магистр",
        teamOtgonjargalEducation3: "Удирдлагын академи-Төрийн удирдлагын анги",
        teamOtgonjargalEducation4: "Япон Улс, “Гунма” мужийн Их сургууль",
        teamOtgonjargalEducation5: "Япон Улс, Чюо их сургууль, Хуульзүйн сургууль",
        teamOtgonjargalProficiency1: "Хуульч",
        teamOtgonjargalProficiency2: "Төрийн удирдлагын менежер",
        teamOtgonjargalProficiency3: "Бизнесийн удирдлага /магистр/",
        teamOtgonjargalProficiency4: "Япон хэлний орчуулагч",
        teamOtgonjargalSkill1: "",
        teamOtgonjargalMembership1: "",

        // АЗБАЯР
        teamAzbayarFname: "АЗБАЯР",
        teamAzbayarLname: "ЦЭЭПЭЛИЙН",
        teamAzbayarTitle: "Үүсгэн байгуулагч, Партнер",
        teamAzbayarDesc1:
          "Ц.Азбаяр нь тус нөхөрлөлийг 2016 онд үүсгэн байгуулсан партнер бөгөөд өнөөдрийг хүртэл тус фирмийн бизнес консалтинг, хууль эрх зүйн сургалт, судалгааны багийг ахлан, амжилттай ажиллаж ирсэн.",
        teamAzbayarDesc2:
          "Тэрээр МУ-ын Улсын их хурлын тамгын газар болон Засгийн газрын хэрэг эрхлэх газарт ажиллаж байсан туршлагатай бөгөөд бизнесийн салбарт 10 гаруй жил ажиллаж, жижиг дунд болон групп компаниудад /үүсгэн байгуулагч, хөрөнгө оруулагч нар болон удирдлагын багт/ хууль эрх зүй, бизнес менежмент, стратеги төлөвлөлт, маркетингийн удирдлагын чиглэлээр мэргэжлийн зөвлөх үйлчилгээг өгч хамтран ажиллаж байна.",
        teamAzbayarDesc3:
          "Тэрээр Банк санхүү, Үнэт цаас хөрөнгийн зах зээл, уул уурхай, бизнесийн эрх зүй болон компанийн засаглал, оюуны өмч, хөдөлмөр, гэрээний эрх зүйн чиглэлээр ажилладаг.",
        teamAzbayarDesc4:
          "Иргэний хэрэг шүүхэд хянан шийдвэрлэх болон захиргааны хэрэг хянан шийдвэрлэх ажиллагаанд үйлчлүүлэгчийн төлөөлөгч, өмгөөлөгчөөр оролцох, арбитрын ажиллагаанд үйлчлүүлэгчийг төлөөлөх, шүүхийн шийдвэр гүйцэтгэх ажиллагаанд үйлчлүүлэгчийн төлөөлөгчөөр оролцох зэрэг хууль зүйн үйлчилгээнүүдийг үзүүлж, ажилласан туршлагатай.",
        teamAzbayarWorkxp1: "",
        teamAzbayarLanguageSkills1: "",
        teamAzbayarEducation1: "",
        teamAzbayarProficiency1: "Хуульч /Магистр/",
        teamAzbayarProficiency2: "Бизнесийн удирдлага /Докторант/",
        teamAzbayarSkill1:
          "“Үнэт цаасны зах зээлд оролцогчдод хууль зүйн зөвлөх үйлчилгээ үзүүлэх” эрхтэй хуульч - Монгол Улсын Санхүүгийн Зохицуулах Хороо, МХХ",
        teamAzbayarSkill2: "Татварын мэргэшсэн хуульч – Татварын мэргэшсэн зөвлөхийн нийгэмлэг, МХХ",
        teamAzbayarSkill3: "Компанийн засаглал - Компанийн засаглалын Үндэсний зөвлөл",
        teamAzbayarMembership1: "“Монголын Хуульчдын Холбоо”-ны гишүүн хуульч, сургагч багш",
        teamAzbayarMembership2: "“Монголын Өмгөөлөгчдийн Холбоо”-ны гишүүн өмгөөлөгч",
        teamAzbayarMembership3: "“Олон Улсын Хуульчдын Холбоо”-ны гишүүн",

        // АЛТАНЦЭЦЭГ
        teamAltantsetsegFname: "АЛТАНЦЭЦЭГ",
        teamAltantsetsegLname: "ЛУВСАН - ОСОРЫН",
        teamAltantsetsegTitle: "Ахлах зөвлөх",
        teamAltantsetsegDesc1:
          "Л.Алтанцэцэг нь МУ-ын хууль зүйн салбарт олон жил үр бүтээлтэй ажиллаж, үнэтэй хувь нэмэр оруулсныг нь төр засаг үнэлж МУ-ын Гавъяат хуульч цол тэмдэгээр шагнуулсан нь манай хамт олны бахархал юм.",
        teamAltantsetsegDesc2: "Тэрээр:",
        teamAltantsetsegDesc3: "• 1986 оноос Хэнтий аймгийн прокурорт",
        teamAltantsetsegDesc4: "• 1998 оноос Улсын Ерөнхий Прокурорт хяналтын прокурор",
        teamAltantsetsegDesc5: "• 2001-2003 онд Нийслэлийн прокурорт хяналтын прокурор нийт 17 жил прокуророор ажилласан туршлагатай бөгөөд",
        teamAltantsetsegDesc6: "• 2004-2024 онд Гадаад харилцааны яаманд ажиллаж байна.",
        teamAltantsetsegDesc7:
          "Тэрээр тус фирмийн эрүүгийн эрх зүйн ахлах зөвлөхөөр ажиллаж, Эрүүгийн хэрэг шүүхэд хянан шийдвэрлэх болон захиргааны хэрэг хянан шийдвэрлэх ажиллагаа, шүүхийн шийдвэр гүйцэтгэх ажиллагаанд зөвлөгөө өгч ажилладаг.",
        teamAltantsetsegWorkxp1: "",
        teamAltantsetsegLanguageSkills1: "",
        teamAltantsetsegEducation1: "Монгол Улсын Их Сургуулийн Хууль зүйн сургууль",
        teamAltantsetsegEducation2: "Удирдлагын академийг тус тус төгсөж,",
        teamAltantsetsegEducation3: "ОХУ, Тайвань, АНУ, АБНЕУ, БНХАУ-д мэргэжил дээшлүүлсэн.",
        teamAltantsetsegProficiency1: "Хуульч /Магистр/",
        teamAltantsetsegProficiency2: "Төрийн удирдлага",
        teamAltantsetsegSkill1: "",
        teamAltantsetsegMembership1: "",

        // НАРАНЦЭЦЭГ
        teamNarantsetsegFname: "НАРАНЦЭЦЭГ",
        teamNarantsetsegLname: "МЯНГААГИЙН",
        teamNarantsetsegTitle: "Ахлах зөвлөх",
        teamNarantsetsegDesc1: "М.Наранцэцэг нь:",
        teamNarantsetsegDesc2: "• Сэлэнгэ аймгийн шүүх, болон Нийслэлийн иргэний хэргийн давж заалдах шатны шүүхэд шүүгчээр 38 жил ажилласан.",
        teamNarantsetsegDesc3:
          "• 2000 оноос шүүгч нарт, хуульчдад иргэний эрх зүй, ИХШХШ эрх зүйн чиглэлээр багшилсан бөгөөд нийслэлийн 9 дүүрэг, 21 аймгийн шүүгч, прокурор, өмгөөлөгч, бусад хуульчдад хуульчийн үргэлжилсэн сургалтыг зохион байгуулж ирсэн.",
        teamNarantsetsegDesc4:
          "Тэрээр тус фирмийн иргэний эрх зүйн ахлах зөвлөхөөр ажиллаж, иргэний хэрэг шүүхэд хянан шийдвэрлэх болон арбитрын хэрэг хянан шийдвэрлэх ажиллагаа, шүүхийн шийдвэр гүйцэтгэх ажиллагаанд зөвлөгөө өгч ажилладаг.",
        teamNarantsetsegWorkxp1: "",
        teamNarantsetsegLanguageSkills1: "",
        teamNarantsetsegEducation1: "Монгол Улсын Их Сургуулийн Хууль зүйн сургууль",
        teamNarantsetsegProficiency1: "Хуульч /Магистр/",
        teamNarantsetsegSkill1: "",
        teamNarantsetsegMembership1: "“Монголын Хуульчдын Холбоо”-ны гишүүн хуульч, сургагч багш",

        // ЦОЛМОН
        teamTsolmonFname: "ЦОЛМОН",
        teamTsolmonLname: "ОДБААТАРЫН",
        teamTsolmonTitle: "Гэрээний мэргэжилтэн",
        teamTsolmonDesc1:
          "О.Цолмон нь анх ажлын гараагаа “Легас” ХХК-д Эрх зүй судлаачаар судалгааны ажил хийж эхэлсэнээс хойш Барилгын салбарт гэрээний мэргэжилтэн, хуулийн зөвлөх зэрэг ажлуудыг хийж байна.",
        teamTsolmonDesc2:
          "Тус хуулийн фирмийн зөвлөх үйлчилгээн дэх гэрээний баримт бичгийн боловсруулалтыг хариуцан ажиллаж, Хөдөлмөрийн харилцаа, Компаний засаглал, Оюуны өмчийн маргаан дахь өмгөөллийн багийн хуульчийн туслахаар ажилладаг болно.",
        teamTsolmonWorkxp1: "",
        teamTsolmonLanguageSkills1: "Англи",
        teamTsolmonEducation1: "Их Засаг олон улсын их сургуулийн бакалавар",
        teamTsolmonProficiency1: "Эрх зүйч",
        teamTsolmonSkill1: "",
        teamTsolmonMembership1: "",

        // ХУЛАН
        teamHulanFname: "ХУЛАН",
        teamHulanLname: "ОТГОНБАЯРЫН",
        teamHulanTitle: "Нягтлан бодогч",
        teamHulanDesc1: "О.Хулан нь тус хуулийн фирмийн өдөр тутмын санхүү тооцоолол, нягтлан бүртгэлийн үйл ажиллагааг хариуцан ажилладаг. ",
        teamHulanDesc2:
          "Тэрээр анх ажлын гараагаа “Татварын ерөнхий газар”-т татварын улсын байцаагчаар ажиллаж эхэлсэн. Мөн ажлынхаа хажуугаар санхүү нягтлан бодох бүртгэлийн ауторсинг үйлчилгээг үзүүлж байна.",
        teamHulanDesc3: "Ажилдаа хариуцлагатай, идэвхи санаачлагатай ажилладаг, өөриймсөг хандлагатай чадварлаг мэргэжилтэн юм.",
        teamHulanWorkxp1: "Татварын ерөнхий газарт Татварын улсын байцаагч",
        teamHulanWorkxp2: "“BUSINESS LAW FIRM” ХХК-ийн нягтлан бодогч",
        teamHulanLanguageSkills1: "",
        teamHulanEducation1: "",
        teamHulanProficiency1: "Нягтлан бодох бүртгэл",
        teamHulanSkill1: "",
        teamHulanMembership1: "",

        //
        contactUsNow: "Бидэнтэй яг одоо холбогдоорой",
        followUs: "Биднийг дагаарай:",
        pageAbout: "Бидний тухай",
        pageServices: "Үйлчилгээ",
        pageTeam: "Манай баг",
        pageJoin: "Бидэнтэй нэгдэх",
        pageContact: "Холбоо барих",

        //
        goBackToHome: "Нүүр хуудас руу оч",
        moreInfo: "Дэлгэрэнгүй",
        getAquote: "Зөвлөгөө авах",
        callNow: "Яг одоо залга",
        notFound: "ХУУДАС ОЛДСОНГҮЙ",
        notFoundMsg: "Вэб хуудас олдохгүй үед 404 хуудас гарч ирэх бөгөөд энэ нь контентыг ашиглах боломжгүй гэсэн дохио юм.",

        //
        bestLawyers: "Туршлага бол үнэ цэнэ",
        homeSection2Title: "Бидний тэмүүлэл",
        homeSection1Desc: "BLF нь хууль зүйн болон менежментийн зөвлөх үйлчилгээг мэргэжлийн өндөр түвшинд үзүүлэхийг эрхэмлэдэг.",
        homeSection2Desc:
          "Манай баг олон жилийн туршлагатай, тал бүрийн мэдлэг, чадвартай хуульч, өмгөөлөгч, бизнес зөвлөх, сургагч багш, мэргэжилтнүүдээс бүрддэг.",
        homeSection3Desc:
          "Үйлчлүүлэгчид маань шаардлага гарсан цаг тухай бүрт нь оновчтой, үр дүнтэй зөвлөгөө, туслалцааг өгч чадна гэдэгт итгэж, хамтран ажилладаг болохыг бид бүхэн ойлгож, хариуцлагатай ажиллагааг хамгийн тэргүүнд тавьдаг.",

        // new!
        ourExperience: "БИДНИЙ ТУРШЛАГА",
        customerReview: "Үйлчлүүлэгчдийн сэтгэгдэл",
        historyTitle: "Бидний түүх",
        historyDesc1:
          "Бид хувь хүн, баг хамт олондоо төдийгүй салбартаа, улс орныхоо хөгжилд хувь нэмрээ оруулж, өөрийн гэсэн онцгой түүхийг бүтээх хүсэл эрмэлзэлээр илүү мэргэшсэн, уян хатан, нээлттэй, бизнесийн хуулийн фирмийг байгуулахаар зориг шулуудан үйл ажиллагаагаа эхлүүлсэн.",
        historyDesc2:
          "Тиймээс бид зөвхөн хуулийн фирм бус, харин хүмүүс маань ажилдаа дуртай, хамтран ажиллах таатай уур амьсгалыг бүрдүүлсэн, үйлчлүүлэгчийнхээ бизнесийн цар хүрээг тэлж, хөгжүүлэхийн төлөө гар нийлэн ажиллаж чаддаг хуулийн фирмийг байгуулахаар зорьсон.",
        historyDesc3:
          "Үйл ажиллагааны маань цар хүрээ, нэр хүнд улам бүр өсөхийн хирээр улс нийгэмдээ арилшгүй үнэ цэнэ, нэр хүндийг бүтээх эрхэм зорилгоо биелүүлэхийн төлөө үргэлж хичээж ажиллах болно.",

        services1Title: "Хуулийн зөвлөх үйлчилгээ",
        services2Title: "Өмгөөлөл",
        services3Title: "Сургалт & Судалгаа",
        services4Title: "Менежментийн зөвлөх үйлчилгээ",

        experience: "Туршлага",
        expereinceDesc: "Манай ажиллаж байсан салбар чиглэл",

        xpTitle1: "Компанийн засаглал",
        xpTitle2: "Хөрөнгийн зах зээл ба үнэт цаас (IPO болон хоёрдогч санал гэх мэт)",
        xpTitle3: "Хөрөнгө оруулалт /дагнасан ба хамтарсан/",
        xpTitle4: "Хөрөнгийн санхүүжилт",
        xpTitle5: "Хөрөнгийн удирдлага",
        xpTitle6: "Бизнесийн бүтцийн өөрчлөлт ба өөрчлөн байгуулалт",
        xpTitle7: "Нэгдэх ба худалдан авалт",
        xpTitle8: "Банк санхүү",
        xpTitle9: "Уул уурхай, эрчим хүч",
        xpTitle10: "Барилга, дэд бүтэц, ",
        xpTitle11: "Газар, дахин төлөвлөлт",
        xpTitle12: "Ашигт малтмалын хайгуул, олборлолт",
        xpTitle13: "Төрийн алба, захиргааны маргаан",
        xpTitle14: "Татвар",
        xpTitle15: "Тендер сонгон шалгаруулалт",
        xpTitle16: "Үл хөдлөх хөрөнгө",
        xpTitle17: "Цагаачлал",
        xpTitle18: "Оюуны өмч",
        xpTitle19: "Хэрэглэгчийн эрх ба Өрсөлдөөн",
        xpTitle20: "Худалдааны болон гэрээний маргаан",
        xpTitle21: "Хөдөлмөрийн маргаан",
        xpTitle22: "Лиценз ба зөвшөөрөл олгох",

        fields: "Талбарууд",
        fieldOfStudy: "Сургалтын чиглэл",

        contentAdvocacyDesc1: "Бидний зорилго бол хэрэг маргааныг хамгийн ухаалаг шийдлээр, түргэн шуурхай, хэмнэлттэй байдлаар шийдвэрлэх явдал юм.",
        contentAdvocacyDesc2:
          "Манай өмгөөллийн баг нь хууль эрх зүйн олон төрлийн, нарийн төвөгтэй асуудлуудыг шийдвэрлэж, ялалт байгуулж ирсэн олон жилийн туршлагатай.",
        contentAdvocacyDesc3:
          "Бид үйлчлүүлэгчийнхээ эрх, хууль ёсны ашиг сонирхолд нийцүүлэн хэрэг маргааныг шийдвэрлэхдээ хэлэлцээр хийх стратеги боловсруулж ажилладаг.",
        contentAdvocacyDesc4:
          "Манай өмгөөлөгч нар хууль тогтоомжийг судлах, бэлтгэх, шүүхийн өмнөх шат, шүүхийн бүх үе шатанд таны төлөөлөн ажиллах мэдлэг, ур чадвар, туршлагатай.",
        contentAdvocacyDesc5:
          "Шүүхийн шатанд бид асуудлыг том зургаар нь харж, илүү үр дүнд хүргэх стратегийг боловсруулж, аливаа жижиг зүйлсийг нэгийг ч орхигдуулалгүй, ямар ч боломжийг дэмий үрэлгүй бүрэн дүүрэн ажиллахыг чухалчилдаг.",
        contentAdvocacyDesc6:
          "Манай хуульч өмгөөлөгч нар оновчтой сайн шийдвэр гаргахын тулд илүү үр дүнтэй багийг бүрдүүлж, асуудлыг олон талаас харж, санал бодлоо уралдуулж, хамтран ажилласны үндсэн дээр үйлчлүүлэгчиддээ илүү шинэлэг, бүтээлч шийдлүүдийг санал болгодог гэдэгт бид итгэдэг.",

        advocacyFieldsTitle1: "Газрын маргаан",
        advocacyFieldsTitle2: "Гэрээний маргаан",
        advocacyFieldsTitle3: "Түншлэл хувьцаа эзэмшигчдийн маргаан",
        advocacyFieldsTitle4: "Үл хөдлөх хөрөнгө ",
        advocacyFieldsTitle5: "Дахин төлөвлөлт",
        advocacyFieldsTitle6: "Өв залгамжлал,",
        advocacyFieldsTitle7: "Гэр бүл",
        advocacyFieldsTitle8: "Хөдөлмөрийн маргаан",
        advocacyFieldsTitle9: "Оюуны өмч",
        advocacyFieldsTitle10: "Төрийн алба",
        advocacyFieldsTitle11: "Сонгууль ",
        advocacyFieldsTitle12: "Гааль , татвар",
        advocacyFieldsTitle13: "Тендер сонгон шалгаруулалт ",
        advocacyFieldsTitle14: "Зөвшөөрөл, улсын бүртгэл",
        advocacyFieldsTitle15: "Компани хуулийн этгээд",
        advocacyFieldsTitle16: "Гэм хор гэрээний бус үүрэг",
        advocacyFieldsTitle17: "Гадаадын иргэн харъяат",
        advocacyFieldsTitle18: "Эрүүгийн гэмт хэрэг",
        advocacyFieldsTitle19: "Шүүхийн маргаан",
        advocacyFieldsTitle20: "Арбитрийн маргаан",

        contentConsultingDesc1:
          "BLF нь хууль эрх зүйн мэдлэг, чадвар болон практик туршлага, арга зүйдээ тулгуурлан үйлчлүүлэгчийнхээ өдөр тутмын ажил хэрэг болон бизнесийн томоохон шийдвэрүүдэд нөлөөлөхүйц чухал мэдээлэл, зөвлөгөөг мэргэжлийн түвшинд тухай бүрт нь үзүүлэхээр зорьж ажилладаг.",
        contentConsultingDesc2:
          "Хуульч, өмгөөлөгч нар маань улам бүр хурдацтай өөрчлөгдөж буй нийгэм, эдийн засаг, бизнесийн орчинд тулгарч буй сорилтуудын талаар бүрэн ойлголт, мэдээлэлтэй байж илүү түргэн шуурхай, үр дүнтэй, хүртээмжтэй үйлчилгээг хүргэхийг зорьдог.",
        contentConsultingDesc3:
          "Үйлчлүүлэгч бүрийнхээ хэрэгцээ, шаардлага болон алсын хараа, бодлого зорилтод илүү анхаарал хандуулж, хууль эрх зүйн болон бизнесийн удирдлагын мэдлэг туршлагыг хослуулан зөвлөх үйлчилгээ үзүүлдгээрээ бид бусдаас ялгардаг.",
        contentConsultingDesc4:
          "Харилцагч бүрийн маань бизнес бусдаас ялгаатай, өөрийн гэсэн онцлогтой байдаг учраас бид боломжтой бүх хувилбаруудыг тооцож, хууль тогтоомжийн дагуу үйлчлүүлэгч бүрийнхээ зорилго, хууль ёсны эрх, ашиг сонирхолд нийцүүлж, тодорхой үр дүнд хүргэхийн тулд тууштай, шинийг эрэлхийлж, нягт нямбай бэлтгэл сайтай ажиллахыг чухалчилдаг.",

        consultingFieldsTitle1: "Уул уурхай, ашигт малтмал",
        consultingFieldsTitle2: "Барилга, Эрчим хүч, дэд бүтэц",
        consultingFieldsTitle3: "Банк, санхүү",
        consultingFieldsTitle4: "Үнэт цаас хөрөнгө оруулалт",
        consultingFieldsTitle5: "Татвар, даатгал",
        consultingFieldsTitle6: "Үл хөдлөх хөрөнгө, бүртгэл",
        consultingFieldsTitle7: "Оюуны өмч",
        consultingFieldsTitle8: "Гадаадын хөрөнгө оруулалт",
        consultingFieldsTitle9: "Компанийн засаглал",

        contentResearchDesc1:
          "Хууль зүйн сургалт маань оролцогчдод онол болон практикийн аль алинд нь суралцах интерактив аргыг санал болгодог бөгөөд үйлчлүүлэгчийн тодорхой хэрэгцээ, шаардлагад нийцүүлэн сургалтын агуулгыг санал болгодогоороо онцлог юм.",
        contentResearchDesc2:
          "Бид бодит кейс дээр тулгуурлан практик, оролцооны арга барилыг илүү баримталдаг бөгөөд ингэснээр та энэхүү мэдлэгээ ажил хэрэг, бизнесийн хүрээнд ашиглахад илүү хялбар, боломжтой болдог.",

        researchFieldsTitle1: "Гэрээний эрх зүй",
        researchFieldsTitle2: "Хөдөлмөрийн эрх зүй",
        researchFieldsTitle3: "Татварын эрх зүй",
        researchFieldsTitle4: "Оюуны өмчийн эрх зүй",
        researchFieldsTitle5: "Компанийн засаглал – эрх зүй",
        researchFieldsTitle6: "Бизнес ба хүний эрх",

        contentManagementDesc1Title: "1. УЛС ТӨРИЙН МЕНЕЖМЕНТ",
        contentManagementDesc2Title: "2. БИЗНЕСИЙН МЕНЕЖМЕНТ",
        contentManagementDesc1:
          "Улс төрийн менежмент бол мэргэжлийн улс төрийн олон талт үйл ажиллагааг багтаасан өргөн хүрээтэй ойлголт бөгөөд байнга өөрчлөгдөж, хөгжиж байдаг салбар юм.",
        contentManagementDesc2:
          "Энэ талбарт кампанит ажлын менежмент, стартеги төлөвлөлт, улс төрийн судалгаа, олон нийттэй харилцах тактик, хууль зүйн зохицуулалт зэрэг олон үйл ажиллагаа багтдаг.",
        contentManagementDesc3:
          "Бизнес менежментийн зөвлөх үйлчилгээ нь байгууллагын гүйцэтгэлийг сайжруулж, бүтээмжийг нэмэгдүүлэх, өөрчлөлтийн менежментийг хэрэгжүүлэх, байгууллагын зорилгод хүрэхэд туслах хөндлөнгийн зөвлөх үйлчилгээ юм.",
        contentManagementDesc4:
          "Бид бизнесийн процесст тулгарч буй асуудлыг тодорхойлох оношилгоог хийж, ажлыг илүү үр ашигтай, үр дүнтэй гүйцэтгэх арга технологи, оновчтой тогтолцоог нэвтрүүлэх шийдлийг санал болгодог.",

        managementFieldsTitle: "Улс төрийн менежмент:",
        managementFieldsTitle2: "Бизнесийн менежмент:",

        managementFields1: "Сонгуулийн компанит ажлын менежмент",
        managementFields2: "Стратеги төлөвлөлт",
        managementFields3: "Улс төрийн брендинг ба мессеж боловсруулалт",
        managementFields4: "Улс төрийн PR-ийн технологи",
        managementFields5: "Хуулийн төсөл боловсруулах",
        managementFields6: "Хууль зүйн дүгнэлт гаргах",

        managementFields2_1: "Компанийн засаглал",
        managementFields2_2: "Групп компанийн менежмент",
        managementFields2_3: "Бизнесийн статеги",
        managementFields2_4: "Маркетингийн удирдлагын зөвлөх үйлчилгээг үзүүлдэг",

        joinUsTitle: "БИДЭНТЭЙ НЭГДЭХ",
        joinUsSlogan: "Хуулийн фирмийн гол хүч бол тэнд ажилладаг хүмүүс юм.",
        joinUsDesc1:
          "BLF-ийн хувьд бид хийж буй бүх зүйлдээ шилдэг байхыг эрхэмлэдэг бөгөөд манай багийн бүх гишүүд чадварлаг, олон талт, бүтээлч, хөдөлмөрч хүмүүс байдаг бөгөөд үйлчлүүлэгчид болон нийгэмдээ өөрчлөлтийг бүтээж чаддаг.",
        joinUsDesc2:
          "Бид карьерынхаа бүхий л үе шатанд сайн хуульдыг эрэлхийлдэг, эрч хүчтэй, хөгжиж буй хуулийн фирм юм. Манай фирм нь хуульчдын хувьд хурдтай, сэтгэл татам ажлын орчинд өсөж дэвжих, сорилтуудыг даван туулах сайхан газар юм.",
        joinUsDesc3:
          "Бидэнтэй нэгдэхийг хүссэн хуульч, өмгөөлөгч нараас манай компанийн түүх болон соёлд маань маш сайн нийцэж, багийн нэг хэсэг болж амжилттай ажиллах, хамтын зорилгодоо урам зоригтойгоор зүтгэж чадах сонирхолтой, хөгжилтэй, эрч хүчтэй, ажил амьдралын тэнцвэрээ хадгалах, хүсэл эрмэлзэлтэй гайхалтай хүмүүсийг хамтран зүтгэгчээр сонгохыг эрмэлздэг.",
        joinUsDesc4:
          "Хэрэв та үндсэн үнэт зүйлсийг маань хуваалцаж, манай шилдэг багийн нэг хэсэг болохыг хүсвэл contact@businesslawfirm.mn  хаягаар бидэнтэй холбогдоорой",
      },
    },

    //
    // 🇨🇳 Chinese
    //
    cn: {
      translation: {
        language: "CN",
        languageName: "中文",

        fadedText1: "成功",
        fadedText2: "是团",
        fadedText3: "队努力",
        fadedText4: "的结果",

        fadedTextSlogan1: "企业法",
        fadedTextSlogan2: "律解决",
        fadedTextSlogan3: "方案",

        slogan: "法律咨询服务 ",
        slogan1: "法律咨询 ",
        slogan2: "宣传援助",
        slogan3: "课程研究",
        slogan4: "咨询 ",
        sectionTitle2: "我们的愿望 ",
        sectionTitle3: "我们的价值 ",
        whyUsDesc:
          "我们的律师事务所致力于维护客户的权利和合法利益，促进客户的业务发展，并巧妙地解决客户的挑战。 凭借十多年的经验，我们经验丰富的团队处理过各种法律事务，包括商业纠纷、劳资冲突、刑事诉讼、民事诉讼和行政事务。 我们在公司治理、银行和金融、资本市场、房地产交易、国内和国际项目方面表现出色。 在我们的整个旅程中，我们赢得了客户的信任，并始终如一地取得了成功的成果。",
        whyUsDesc2: "我们与客户和客人建立长期关系而感到自豪，而不仅仅是关注结果。",
        customCardTitle1: "远景",
        customCardDesc1: "我们立志成为行业内卓越的商业律师事务所，致力于为我们尊敬的客户提供无与伦比的质量和价值。",
        customCardTitle2: "使命",
        customCardDesc2: "我们深入了解商业领域的复杂性和客户的独特需求，致力于提供优质、快捷、创新的法律服务 .",
        customCardTitle3: "责任道德",
        customCardDesc3: "我们在业务、关系和决策流程的各个方面都严格致力于提高道德标准。 ",
        customCardTitle4: "创新",
        customCardDesc4: "我们致力于不断挑战自我，培养持续学习的文化，并积极寻求改进，以开拓现代和创造性的解决方案。 ",
        customCardTitle5: "团队 ",
        customCardDesc5: "我们致力于营造积极的环境，积极为我们的团队、家庭、客户和整个社会的福祉做出贡献.",
        ourSeriviceTitle: "我们的服务",
        ourSeriviceDesc1:
          "我们的商业律师事务所成立于2016年，一直坚定不移地为国内外公民以及法人实体提供法律咨询、援助、辩护、培训和研究等专业服务。 我们的综合方法植根于蒙古和国际法的法律框架内。",
        ourSeriviceDesc2:
          "为了我们的客户和委托人打造竞争优势，我们对商业和法律环境的变化保持警惕，确保及时调整。 我们的承诺延伸到发布符合客户权利和合法利益的精确法律裁决。",
        featureTitle1: "法律咨询服务",
        featureTitle2: "宣传和法律援助",
        featureTitle3: "课程研究",
        ourServiceTitle1: "法律咨询服务",
        ourServiceDesc1: "我们专注于根据特定业务领域和行业细微差别定制服务，提供专业的法律咨询服务，旨在解决客户的独特挑战并有效降低风险",
        ourServiceDesc1Helper: "在这个",
        ourServiceDesc1List1: "商业法和公司治理",
        ourServiceDesc1List2: "税收和保险",
        ourServiceDesc1List3: "证券及股票市场",
        ourServiceDesc1List4: "银行、金融和融资",
        ourServiceDesc1List5: "建筑、能源和基础设施",
        ourServiceDesc1List6: "贸易、商业和外国投资",
        ourServiceDesc1List7: "知识产权、版权和专利",
        ourServiceDesc1List8: "采矿和自然资源",
        ourServiceDesc1List9: "例子包括房地产和资产管理",
        ourServiceTitle2: "咨询服务类型",
        ourServiceDesc2_1: "商业咨询服务：就商业活动的监管框架提供法律咨询，就商业活动中合作企业的结构和组织提供建议.",
        ourServiceDesc2_2:
          "综合企业服务：提供合资企业设立、股份出售、购买和重组方面的指导。 起草和登记文件和合同，为公司的日常业务活动提供咨询，并担任值得信赖的公司秘书。",
        ourServiceDesc2_3:
          "熟练的合同管理：我们专注于大型合同和协议的起草和细致监控。 我们的团队擅长准备商业和贸易合同，确保它们符合符合国际规范的高专业标准。",
        ourServiceDesc2_4: "法律尽职调查和报告：我们进行彻底的法律尽职调查，审查公司的法律合规性。 我们的团队为参与买卖股票的投资者准备独立报告。",
        ourServiceDesc2_5:
          "起草法律意见书：我们通过仔细分析借款人和寻求资金投资的公司的运营情况来制定法律意见书。 我们的专业知识延伸至融资谈判和证券发行流程。",
        ourServiceDesc3_1:
          "我们提供一系列法律服务，包括在民事和行政诉讼、刑事和轻罪诉讼、仲裁诉讼和执行诉讼中代表客户。 针对客户的需求和要求，我们还进行法律环境研究并举办法律和组织发展课程。",
        ourServiceDesc3_2:
          "我们的团队拥有丰富的经验，在首都乌兰巴托和 21 个省份为超过 300,000 名公民组织了培训和发展活动，覆盖蒙古各地 100 多个地区和定居点。 我们的服务范围涵盖蒙古大学 (MU) 的政府机构、国家和地方组织、商业集团公司、蒙古大学的顶尖企业以及 OU 的非政府组织，总计约 1,000 个组织。",
        ourTeamTitle: "我们的队伍",
        ourTeamDesc1:
          "我们的团队由在蒙古立法、法律体系、商业环境和政治关系方面拥有深厚专业知识的律师组成。 此外，我们拥有税务、公司治理和证券市场方面的专家，他们都拥有丰富的知识和经验，可以满足客户的多样化需求。",
        ourTeamDesc2:
          "我们的顾问都是经验丰富的律师，拥20-30 年的经验，曾在法院和检察官办公室工作过。 他们在法律实践中的丰富经验使他们拥有丰富的知识和对各种法律复杂性的深刻理解。",

        education: "教育",
        membership: "会员资格",
        workxp: "经验",
        skills: "熟练程度",
        languageSkills: "语言",
        proficiency: "职业",

        ourStatTitle: "我们的影响力",
        ourStat1: "提供法律援助",
        ourStat2: "合作机构",
        ourStat3: "诉讼",
        ourStat4: "教学与研究",

        testimonials1Name: "B. NARANBAATAR",
        testimonials1Title: "General Director of Hanbogd Khurd LLC",
        testimonials1Desc:
          "我们从未想象过可以以如此有趣且容易理解的方式教导错综复杂的法律知识。 我们感谢您向我们的员工传授有关劳动法和安全的基本知识并彻底解决他们的问题。 我们很高兴继续与您合作。",

        testimonials2Name: "J. BATTULGA",
        testimonials2Title: "Director of Galba Station LLC",
        testimonials2Desc:
          "我们作为奥尤陶勒盖有限责任公司的供应商，我们衷心感谢您在制定奥尤陶勒盖有限公司所需的必要法律文件、文书和法规方面所提供的帮助。感谢您的宝贵支持。",

        testimonials3Name: "S. OTGONBAT",
        testimonials3Title: "General Director of Micro Globe Group",
        testimonials3Desc:
          "俗话说“如果你想成为领导者，就与领导者一起工作”，这句话是对的。 与专业人士合作不仅可以节省时间，而且具有成本效益。 我们感谢您对我们服务的信任，并感谢您允许我们检查您的业务并提供及时、负责任的法律建议和帮助。",

        partnersTitle: "合作机构",

        contatctUsTitle: "请联系我们",
        loading: "加载中...",
        inputName: "姓名",
        inputPhone: "电话",
        inputEmail: "电子邮件地址",
        inputMessage: "信息",
        inputSubmit: "发送",
        inputSuccessMessage: "您的表格已成功提交。 我们会尽快与您联系。",

        addressTitle: "地址",
        addressFull: "蒙古国乌兰巴托市苏赫巴托区一区和平大道蓝天塔11层",
        address1: "蒙古, 乌兰巴托市",
        address2: "苏赫巴托尔第一区，和平大道",
        address3: "蓝天大厦11层",

        // ШИНЭГЭРЭЛ
        teamShinegerelFname: "SHINEGEREL",
        teamShinegerelLname: "Amarsanaa",
        teamShinegerelTitle: "执行董事",
        teamShinegerelDesc1:
          "A.Shinegerel 于 2012 年开始从事其职业，并于 2022 年开始担任律师和合伙企业的辩护人。除了在上述期间担任律师之外，由于他在负责业务开发方面的成功工作，外部 随着公司关系和合作的发展，她已成功晋升为合伙人及合伙企业的执行管理层。",
        teamShinegerelDesc2: "她在投资、房地产、证券市场、刑法、土地纠纷、家庭继承和合同法方面拥有专业经验。",
        teamShinegerelDesc3: "她还成功管理了法律框架研究和法律意见项目，积累了丰富的经验。",
        teamShinegerelWorkxp1: "",
        teamShinegerelLanguageSkills1: "",
        teamShinegerelEducation1: "",
        teamShinegerelProficiency1: "律师",
        teamShinegerelProficiency2: "翻译者",
        teamShinegerelSkill1: "拥有“向证券市场参与者提供法律咨询服务”权利的律师 - 蒙古金融监管委员会",
        teamShinegerelSkill2: "",
        teamShinegerelMembership1: "“蒙古国律师协会”会员“.",
        teamShinegerelMembership2: "“蒙古倡导协会”会员“.",
        teamShinegerelMembership3: "“国际律师协会”会员“.",

        // ГҮЛЖАН
        teamGuljanFname: "Gulzhan",
        teamGuljanLname: "Sherhan",
        teamGuljanTitle: "律师",
        teamGuljanDesc1: "Sh.Gulzhan 是负责我们公司外交关系、合同、保险和劳动法的经验丰富的律师之一。",
        teamGuljanDesc2: "自在“人民保险”有限责任公司开始律师职业生涯以来，她主要从事矿业、金融保险、证券、投资、外贸等领域的工作，积累了知识和经验。",
        teamGuljanDesc3: "迄今为止，我们已成功管理了50多场英语和土耳其语商务谈判，并在高价的大型合同中保护了客户的合法权益和利益。",
        teamGuljanWorkxp1: "",
        teamGuljanLanguageSkills1: "",
        teamGuljanEducation1: "土耳其加齐大学法学院，学士",
        teamGuljanEducation2: "理学硕士 密歇根州立大学法学院",
        teamGuljanEducation3: "人文大学，英语翻译，学士",
        teamGuljanProficiency1: "律师",
        teamGuljanProficiency2: "翻译者",
        teamGuljanSkill1: "拥有蒙古国金融监管委员会“为证券市场参与者提供法律咨询服务”权的律师",
        teamGuljanMembership1: "“蒙古国律师协会”会员“.",

        // БИЛГҮҮНТӨГС
        teamBilguuntugsFname: "Bilguuntugs",
        teamBilguuntugsLname: "Davaasuren",
        teamBilguuntugsTitle: "助理律师",
        teamBilguuntugsDesc1: "D.Bilguuntugs 为协会辩护和法庭案件的日常活动提供技术和实际协助，并准备和分析必要的事实和数据，并准备文件。",
        teamBilguuntugsDesc2: "该员工是一位熟练的专业人士，具有良好的工作能力和绩效，并且可以成为一位赢得其公司律师和律师信任的优秀专家。",
        teamBilguuntugsWorkxp1: "“商业律师事务所” LLC 助理律师",
        teamBilguuntugsLanguageSkills1: "韩国人",
        teamBilguuntugsEducation1: "内务大学法律与社会科学学院法学学士",
        teamBilguuntugsEducation2: "韩国昌信大学警察管理学学士",
        teamBilguuntugsProficiency1: "",
        teamBilguuntugsSkill1: "",
        teamBilguuntugsMembership1: "",

        // ОТГОН
        teamOtgonFname: "Otgon",
        teamOtgonLname: "Batjargal",
        teamOtgonTitle: "总经理",
        teamOtgonDesc1:
          "B. Otgon不仅确保法律事务所日常运营的有序组织，还在法律事务及诉讼案件中提供技术和实践支持。他具备出色的文档编写、研究分析以及法律文件起草能力。",
        teamOtgonDesc2: "该员工是一位高效且专业的优秀人才，以卓越的工作能力和执行力赢得了事务所律师和法律顾问的信任。",
        teamOtgonWorkxp1: "执行董事 – “GOLDEN STIRRUP MINING MACHINERY” 有限公司 (2024年4月 – 2024年7月)",
        teamOtgonWorkxp2: "执行董事 – “GOLDEN SPRING INTERNATIONAL” 有限公司 (2024年1月 – 2024年7月)",
        teamOtgonWorkxp3: "人力资源经理 – “GOLDEN SPRING INTERNATIONAL” 有限公司 (2022年9月 – 2024年1月)",
        teamOtgonLanguageSkills1: "英语",
        teamOtgonLanguageSkills2: "中文",
        teamOtgonEducation1: "工商管理学士学位 – 台湾明道大学",
        teamOtgonEducation2: "法学学士学位 – 蒙古国立大学法学院",
        teamOtgonProficiency1: "",
        teamOtgonSkill1: "",
        teamOtgonMembership1: "",

        // ОТГОНЖАРГАЛ
        teamOtgonjargalFname: "Otgonjargal",
        teamOtgonjargalLname: "Sumya",
        teamOtgonjargalTitle: "合伙人、律师",
        teamOtgonjargalDesc1: "S. Otgonjargal 自 2024 年起一直在该律师事务所担任合伙人和律师。",
        teamOtgonjargalDesc2: "自2006年起，他成为蒙古律师协会会员，从事律师工作已有18年，是一名公认的合格律师，在外国公民事务方面拥有丰富的经验。",
        teamOtgonjargalDesc3:
          "S.Otgonjargal合伙人遵守职业道德规则，根据公民和法人的要求和需求，依照法律法规提供最佳法律咨询，并提供法律援助和辩护服务。",
        teamOtgonjargalWorkxp1: "",
        teamOtgonjargalLanguageSkills1: "英语",
        teamOtgonjargalLanguageSkills2: "日语",
        teamOtgonjargalEducation1: "他们的 Zasazh 大学，V.Sk",
        teamOtgonjargalEducation2: "日本经济大学 硕士",
        teamOtgonjargalEducation3: "管理学院-公共管理系",
        teamOtgonjargalEducation4: "群马大学",
        teamOtgonjargalEducation5: "中央大学法学院",
        teamOtgonjargalProficiency1: "律师",
        teamOtgonjargalProficiency2: "公共行政经理",
        teamOtgonjargalProficiency3: "工商管理 / 硕士 /",
        teamOtgonjargalProficiency4: "日语翻译",
        teamOtgonjargalSkill1: "",
        teamOtgonjargalMembership1: "",

        // АЗБАЯР
        teamAzbayarFname: "Azbayar",
        teamAzbayarLname: "Tseepel",
        teamAzbayarTitle: "创始人、合伙人",
        teamAzbayarDesc1: "TS。 Azbayar 是该公司的合伙人，于 2016 年创立，并成功领导了该公司的业务咨询、法律培训和研究团队。",
        teamAzbayarDesc2: "曾在密苏里大学国大秘书处、政府事务秘书处工作过。 且已在商界工作10年。 提供策划和营销管理领域的专业咨询服务。",
        teamAzbayarDesc3: "他的工作领域包括银行和金融、证券和资本市场、采矿、商业法和公司治理、知识产权、劳动和合同法。",
        teamAzbayarDesc4:
          "他在提供法律服务方面拥有丰富的经验，例如作为客户代表和律师参与民事和行政诉讼、代表客户参与仲裁程序以及作为客户代表参与法院判决执行程序。",
        teamAzbayarWorkxp1: "",
        teamAzbayarLanguageSkills1: "",
        teamAzbayarEducation1: "",
        teamAzbayarProficiency1: "律师/硕士/",
        teamAzbayarProficiency2: "企业管理/博士生/",
        teamAzbayarSkill1: "拥有“向证券市场参与者提供法律咨询服务”权利的律师 - 蒙古金融监管委员会",
        teamAzbayarSkill2: "注册税务律师 – 注册税务顾问协会、ITC ",
        teamAzbayarSkill3: "公司治理 - 国家公司治理委员会",
        teamAzbayarMembership1: "“蒙古律师协会”会员、律师、培训师",
        teamAzbayarMembership2: "“蒙古倡导协会”会员“.",
        teamAzbayarMembership3: "“国际律师协会”会员“.",

        // АЛТАНЦЭЦЭГ
        teamAltantsetsegFname: "Altantsetseg",
        teamAltantsetsegLname: "Luvsan-Osor",
        teamAltantsetsegTitle: "高级顾问",
        teamAltantsetsegDesc1:
          "L.Altantsetse 被政府授予密西根大学荣誉律师称号，以表彰他多年来卓有成效的工作和对密西根大学法律领域的宝贵贡献，这是我们社区的骄傲。",
        teamAltantsetsegDesc2: "她",
        teamAltantsetsegDesc3: "• 1986年起担任肯特省检察官",
        teamAltantsetsegDesc4: "• 1998年起任检察长办公室监察检察官",
        teamAltantsetsegDesc5: "• 2001年至2003年担任都厅检察厅监督检察官，共有17年的检察官经验。",
        teamAltantsetsegDesc6: "• Worked in the Ministry of Foreign Affairs from 2004 to 2024.",
        teamAltantsetsegDesc7: "她担任公司的高级刑事律师，就刑事和行政诉讼及执行提供咨询",
        teamAltantsetsegWorkxp1: "",
        teamAltantsetsegLanguageSkills1: "",
        teamAltantsetsegEducation1: "蒙古大学法学院",
        teamAltantsetsegEducation2: "从管理学院毕业后",
        teamAltantsetsegEducation3: "俄罗斯、台湾、美国、东盟和中国的高级专业培训。",
        teamAltantsetsegProficiency1: "律师/硕士/",
        teamAltantsetsegProficiency2: "状态管理",
        teamAltantsetsegSkill1: "",
        teamAltantsetsegMembership1: "",

        // НАРАНЦЭЦЭГ
        teamNarantsetsegFname: "Narantsetseg",
        teamNarantsetsegLname: "Myangaa",
        teamNarantsetsegTitle: "高级顾问",
        teamNarantsetsegDesc1: "她",
        teamNarantsetsegDesc2: "•曾在色楞格地方法院和大都会民事上诉法院担任法官38年。",
        teamNarantsetsegDesc3: "•自2000年以来，她一直在民法和民法领域教授法官和律师。",
        teamNarantsetsegDesc4: "她担任公司的高级民事法律顾问，就民事诉讼、仲裁和执行提供咨询",
        teamNarantsetsegWorkxp1: "",
        teamNarantsetsegLanguageSkills1: "",
        teamNarantsetsegEducation1: "蒙古大学法学院",
        teamNarantsetsegProficiency1: "律师/硕士/",
        teamNarantsetsegSkill1: "",
        teamNarantsetsegMembership1: "“蒙古律师协会”会员、律师、培训师",

        // ЦОЛМОН
        teamTsolmonFname: "Tsolmon",
        teamTsolmonLname: "Odbaatar",
        teamTsolmonTitle: "合同专家",
        teamTsolmonDesc1: "O.Tsolmon 的职业生涯始于“Legas” LLC 的法律研究员，并一直担任建筑行业的合同专家和法律顾问。",
        teamTsolmonDesc2: "负责律师事务所咨询服务中合同文件的制定，并担任劳动关系、公司治理、知识产权纠纷方面辩护团队的法律助理。",
        teamTsolmonWorkxp1: "",
        teamTsolmonLanguageSkills1: "英语",
        teamTsolmonEducation1: "伊赫扎萨格大学学士",
        teamTsolmonProficiency1: "律师",
        teamTsolmonSkill1: "",
        teamTsolmonMembership1: "",

        // ХУЛАН
        teamHulanFname: "Hulan",
        teamHulanLname: "Otgonbayar",
        teamHulanTitle: "会计",
        teamHulanDesc1: "O.胡兰负责律师事务所的日常财务计算和会计工作。",
        teamHulanDesc2: "她的职业生涯始于税务总局担任国家税务稽查员。 除了我们的工作外，我们还提供财务和会计外包服务。",
        teamHulanDesc3: "她是一位负责任、积极主动、自我激励的专业人士。",
        teamHulanWorkxp1: "税务总局国家税务督察",
        teamHulanWorkxp2: "“商业律师事务所”有限责任公司会计师",
        teamHulanLanguageSkills1: "",
        teamHulanEducation1: "",
        teamHulanProficiency1: "会计",
        teamHulanSkill1: "",
        teamHulanMembership1: "",

        //
        contactUsNow: "立即联系我们",
        followUs: "跟着我们",
        pageAbout: "关于我们",
        pageServices: "服务",
        pageTeam: "我们的队伍",
        pageJoin: "加入我们",
        pageContact: "接触",

        //
        goBackToHome: "返回首页",
        moreInfo: "更多的",
        getAquote: "获取报价",
        callNow: "现在打电话",
        notFound: "找不到网页",
        notFoundMsg: "当找不到所请求的网页时，就会出现 404 页面，表明该内容不可用。",
        bestLawyers: "经验就是价值",
        homeSection2Title: "是什么让我们与众不同？",
        homeSection1Desc: "",
        homeSection2Desc:
          "我们公司由一支多技能的律师、律师、商业和管理顾问、培训师和熟练的专业人士组成的团队组成，他们了解我们的客户相信我们能够及时取得良好的结果。",
        homeSection3Desc: "",

        // new!
        ourExperience: "我们的经验",
        customerReview: "顾客评论",
        historyTitle: "我们的故事",
        historyDesc1:
          "我们勇敢地踏上了征程，旨在建立一家专业化、适应性强、包容性的商业律师事务所。 我们的愿望不仅是促进个人和团队的成长，而且为我们的行业、我们的国家做出重大贡献，并创造我们自己独特的遗产。",
        historyDesc2:
          "因此，我们的目标不仅仅是建立一家律师事务所，而是营造一个让我们的团队在工作中获得成就感、营造融洽的氛围、无缝协作的环境，以推进和增强客户的业务。",
        historyDesc3: "随着我们的活动和声誉不断扩大，我们仍然坚定不移地致力于在社会中创造持久的价值和积极的声誉。",

        services1Title: "法律咨询服务",
        services2Title: "宣传",
        services3Title: "教学与研究",
        services4Title: "管理咨询服务",

        experience: "经验",
        expereinceDesc: "我们的工作领域",

        xpTitle1: "公司治理",
        xpTitle2: "资本市场和证券（例如首次公开募股和二次发行）",
        xpTitle3: "投资/个人及联合/",
        xpTitle4: "资本融资",
        xpTitle5: "资产管理",
        xpTitle6: "业务重组及重组",
        xpTitle7: "并购",
        xpTitle8: "银行和金融",
        xpTitle9: "采矿和能源",
        xpTitle10: "建筑物、基础设施",
        xpTitle11: "土地和重建",
        xpTitle12: "矿产勘探与生产",
        xpTitle13: "公务员及行政纠纷",
        xpTitle14: "税",
        xpTitle15: "招标选择",
        xpTitle16: "房地产",
        xpTitle17: "移民",
        xpTitle18: "知识产权",
        xpTitle19: "消费者权利与竞争",
        xpTitle20: "商业和合同纠纷",
        xpTitle21: "劳动争议",
        xpTitle22: "许可和许可",

        fields: "领域",
        fieldOfStudy: "研究领域",

        contentAdvocacyDesc1: "我们的目标是以智慧、效率和成本效益解决纠纷为指导原则",
        contentAdvocacyDesc2: "凭借多年的经验，我们的法律团队擅长解决并赢得各种复杂的法律事务。",
        contentAdvocacyDesc3: "我们制定符合客户权利和合法利益的谈判策略来解决争议。",
        contentAdvocacyDesc4: "我们的律师拥有进行彻底研究、起草立法的专业知识，并在预审和法庭诉讼的所有阶段熟练地代表您。",
        contentAdvocacyDesc5:
          "在诉讼中，我们的方法集中于对案件的全面了解、制定最佳结果的策略以及对细节的一丝不苟。 我们全力以赴，最大限度地利用每一个机会，确保在追求客户目标的过程中不遗漏任何细节。",
        contentAdvocacyDesc6:
          "我们理念的核心在于相信我们的律师和辩护律师作为有凝聚力的团队运作时才能最有效地发挥作用。 通过利用多元化的观点、参与良性竞争和促进合作，我们营造了一个有利于为客户的挑战提供创新和创造性解决方案的环境。",

        advocacyFieldsTitle1: "土地纠纷",
        advocacyFieldsTitle2: "合同纠纷",
        advocacyFieldsTitle3: "合伙企业股东之间的纠纷",
        advocacyFieldsTitle4: "房地产",
        advocacyFieldsTitle5: "重新规划",
        advocacyFieldsTitle6: "遗产，",
        advocacyFieldsTitle7: "家庭",
        advocacyFieldsTitle8: "劳动争议",
        advocacyFieldsTitle9: "知识产权",
        advocacyFieldsTitle10: "公务员",
        advocacyFieldsTitle11: "选举",
        advocacyFieldsTitle12: "关税和税收",
        advocacyFieldsTitle13: "招标选择",
        advocacyFieldsTitle14: "许可证和国家注册",
        advocacyFieldsTitle15: "公司是法人实体",
        advocacyFieldsTitle16: "侵权非合同义务",
        advocacyFieldsTitle17: "外国公民",
        advocacyFieldsTitle18: "刑事犯罪",
        advocacyFieldsTitle19: "诉讼",
        advocacyFieldsTitle20: "仲裁纠纷",

        contentConsultingDesc1:
          "凭借我们丰富的法律知识、磨练的技能、实践经验和完善的方法，BLF 致力于为我们的客户提供关键信息和专家建议。 我们的目标是帮助他们以专业和自信的方式进行日常运营和重大业务决策。",
        contentConsultingDesc2:
          "我们的律师和辩护律师团队致力于提供迅速、高效且方便的服务。 我们优先考虑对当今快速发展的社会、经济和商业环境中固有的挑战的深入了解，确保我们的客户获得知情和全面的支持。",
        contentConsultingDesc3:
          "我们通过提供将法律专业知识与商业管理智慧相结合的咨询服务而脱颖而出。 我们的方法高度重视了解每个客户的独特需求和要求，以及他们的总体愿景和政策目标。",
        contentConsultingDesc4:
          "认识到每个客户业务的独特性及其独特的特点，我们精心探索所有潜在的途径。 我们在法律范围内维护客户的目标和合法权益，以勤奋、坚持、创新、充分准备为核心，以取得具体、良好的成果。",

        consultingFieldsTitle1: "采矿和矿物",
        consultingFieldsTitle2: "建筑、能源和基础设施",
        consultingFieldsTitle3: "银行和金融",
        consultingFieldsTitle4: "证券投资",
        consultingFieldsTitle5: "税收和保险",
        consultingFieldsTitle6: "不动产及登记",
        consultingFieldsTitle7: "知识产权",
        consultingFieldsTitle8: "外来投资",
        consultingFieldsTitle9: "公司治理",

        contentResearchDesc1:
          "我们的法律课程为参与者提供互动学习体验，将理论知识与实际应用相结合。 我们的独特之处在于我们的定制方法，我们定制课程内容以满足客户的特定需求和要求。",
        contentResearchDesc2: "我们采用植根于现实案例的实用实践方法，使您能够轻松地将这些知识直接应用到您的业务运营中。",

        researchFieldsTitle1: "合同法",
        researchFieldsTitle2: "劳动法",
        researchFieldsTitle3: "税收法",
        researchFieldsTitle4: "版权",
        researchFieldsTitle5: "公司治理 – 法律",
        researchFieldsTitle6: "商业与人权",

        contentManagementDesc1Title: "1. 政治管理",
        contentManagementDesc2Title: "2. 企业管理",
        contentManagementDesc1: "政治管理是一个多层面的概念，涵盖专业政治参与的各个方面。 这是一个不断演变和发展的动态领域。",
        contentManagementDesc2: "该领域涵盖从竞选管理和战略规划到政治研究、公共关系策略和法律监管等一系列活动。",
        contentManagementDesc3: "企业管理咨询服务需要外部咨询，旨在提高组织绩效、提高生产力、促进变革管理并最终实现组织目标。",
        contentManagementDesc4:
          "我们专注于进行诊断以查明业务流程中的问题，然后提供旨在实施技术和优化系统的定制解决方案。 我们的目标是提高工作场所的效率和效果。",

        managementFieldsTitle: "政治管理：",
        managementFieldsTitle2: "商业管理：",

        managementFields1: "竞选活动管理",
        managementFields2: "策略计划",
        managementFields3: "政治品牌和信息处理",
        managementFields4: "政治公关技术",
        managementFields5: "起草法律",
        managementFields6: "出具法律意见书",

        managementFields2_1: "公司治理",
        managementFields2_2: "集团公司管理",
        managementFields2_3: "经营策略",
        managementFields2_4: "提供营销管理咨询服务",

        joinUsTitle: "加入我们",
        joinUsSlogan: "律师事务所的支柱在于人才。",
        joinUsDesc1:
          "在 BLF，我们对卓越的追求渗透到工作的各个方面。 我们的团队由才华横溢、多元化、富有创造力和勤奋的个人组成，他们始终致力于为我们的客户和社区产生积极影响。",
        joinUsDesc2:
          "我们是一家充满活力且不断扩张的律师事务所，积极寻找处于职业生涯各个阶段的优秀律师。 我们公司为职业发展提供了卓越的环境，个人可以在快节奏、刺激的环境中茁壮成长并应对挑战。",
        joinUsDesc3:
          "我们正在积极寻找律师和代理律师加入我们的团队，我们的选拔过程会优先考虑符合我们公司丰富传统和文化的个人。 我们重视表现出强烈职业道德、分享我们实现共同目标的热情并为我们充满活力的工作环境做出积极贡献的团队成员。 理想的候选人表现出兴趣、活力和雄心，同时保持健康的工作与生活平衡。",
        joinUsDesc4: "如果您认同我们的核心原则并有意愿加入我们尊敬的团队，我们鼓励您通过 contact@businesslawfirm.mn 与我们联系。",
      },
    },

    //
    // 🇹🇷 Turkey
    //

    tr: {
      translation: {
        language: "TR",
        languageName: "Turkey",

        fadedText1: "Başarı",
        fadedText2: "bir",
        fadedText3: "takım",
        fadedText4: "işidir’",

        fadedTextSlogan1: "Ticaret",
        fadedTextSlogan2: "için yasal",
        fadedTextSlogan3: "çözümler",

        slogan: "HUKUKİ DANIŞMALIK HİZMETLERİ",
        slogan1: "Hukuk danışmanlığı",
        slogan2: "Avukatlık",
        slogan3: "Eğitim ve araştırma",
        slogan4: "Yönetim danışmanlığı hizmetleri",

        sectionTitle2: "AMACIMIZ",
        sectionTitle3: "DEĞERLERİMİZ",

        whyUsDesc:
          "Müşterilerimizin haklarını ve yasal çıkarlarını korumak, işlerinin gelişmesini desteklemek ve karşılaştıkları sorunları verimli şekilde çözmek için çalışıyoruz. Taklım arkadaşlarımız 10 yılı aşkın sürede iş anlaşmazlıkları, çalışma ve iş kanunu uyuşmazlıkları, ceza ve medeni kanun, idari davalar, kurumsal yönetim, bankacılık ve finans, sermaye piyasası, gayrimenkul, Uluslararası ve yurt içi projeler gibi çeşitli hukui ve yasal sorunlarda görev alarak, müşterilerimizin güvenini korumakla birlikte başarılı çalışmalarına devam etmektedir.",
        whyUsDesc2:
          "Sadece faaliyet sonuçlarına odaklanmakla kalmayıp müşterilerimizle uzun vadeli ilişki ve iletişim kurarak, istikrarlı işbirliklerimizi sürdürmekten gurur duyuyoruz.",
        customCardTitle1: "VİZYONUMUZ",
        customCardDesc1: "Müşterilerimize kalite ve değer sunan, sektörün öncüsü ticaret hukuku firması olmak",
        customCardTitle2: "MİSYONUMUZ",
        customCardDesc2:
          "Misyonumuz, müşterilerimizin iş sektöründeki ve başka ihtiyaçlarını tam olarak anlayıp en iyi, hızlı ve yenilikçi hukuk hizmetlerini sunmaktır.",
        customCardTitle3: "SORUMLULUK VE ETİK",
        customCardDesc3: "İş ilişkilerimize sorumlulukla yaklaşarak, karar alma sürecinde yüksek etik kurallarını takip etmekteyiz",
        customCardTitle4: "İNOVASYON",
        customCardDesc4: "Devamlı modern ve yaratıcı çözümleri aramakla beraber sürekli öğrenme ve gelişme gayretindeyiz",
        customCardTitle5: "TAKIMIMIZ",
        customCardDesc5: "Toplumun refahı ve adaletin tesisi için birlikte mücadele edecek yetenekli takım arkadaşlarını oluşturarak çalışma",
        ourSeriviceTitle: "HİZMETLERİMİZ",
        ourSeriviceDesc1:
          "2016 yılında kurulduğu günden bugüne kadarki sürede BLF firması hem yerli hem yabancı kişi ve tüzel kişilere Moğolistan Cumhuriyeti ve Uluslararası hukuk çerçevesinde hukuk ve iş yönetimi tavsiyeleri, destekleme, eğitim, araştırma ve danışmalık hizmetlerini sunmaktadır.",
        ourSeriviceDesc2:
          "Müşterilerimize rekabet avantajı sağlamak için iş dünyası ve hukuk ortamındaki değişiklikleri zamanında algılayarak, onların hak ve yasal çıkarlarına uygun isabetli çözümleri sunmak için çalışıyoruz.",
        featureTitle1: "HUKUKİ DANIŞMANLIK HİZMETLERİ",
        featureTitle2: "AVUKATLIK VE HUKUKİ YARDIM",
        featureTitle3: "EĞİTİM VE ARAŞTIRMA",
        ourServiceTitle1: "HUKUKİ DANIŞMANLIK HİZMETLERİ",
        ourServiceDesc1:
          "Şirketler ve kurumların faaliyet alanı, sektör özelliğine uygun hizmetler sunarak, karşılaştıkları sorunları çözüme, riskleri azaltmaya yönelik profesyonel hukuki danışmanlık hizmetleri sunuyoruz.",
        ourServiceDesc1Helper: "Bunlar",
        ourServiceDesc1List1: "Ticaret hukuku ve şirket yönetimi",
        ourServiceDesc1List2: "Vergi ve sigorta",
        ourServiceDesc1List3: "Hisse senedi ve Menkul kıymetler borsası",
        ourServiceDesc1List4: "Bankacılık, finans ve fonlama",
        ourServiceDesc1List5: "İnşaat, enerji ve altyapı",
        ourServiceDesc1List6: "Ticaret, iş ve yabancı yatırımları",
        ourServiceDesc1List7: "Fikri mülkiyet, telif hakkı ve patent",
        ourServiceDesc1List8: "Madencilik ve doğal kaynaklar",
        ourServiceDesc1List9: "Gayrimenkul, varlık yönetimi gibi sektörlerden bahsedebiliriz.",
        ourServiceTitle2: "DANIŞMALIK HİZMETİ TÜRLERİ",
        ourServiceDesc2_1:
          "Ticari danışmanlık: Ticari faaliyetlerin yasal ortamı hakkında hukuki danışmanlık sunarak, ticari faaliyetlerde işbirliği yapmak için iş yapısı, organizasyonu hakkında çözümler sunmak, önerilerde bulunmak",
        ourServiceDesc2_2:
          "Ortak şirket kurulumu, hisse satma ve satın alma, yapısal değişiklik işlemleri ile ilgili öneriler, belgeler, sözleşmelerin taslaklarını hazırlama ve kaydettirme, şirketin günlük iş faaliyetleri danışmanlığı, kurumsal genel sekreterlik görevlerinin üstlenilmesi",
        ourServiceDesc2_3:
          "Büyük ölçekli sözleşme ve anlaşma taslaklarının hazırlama ve kontrolü: iş ve ticaret sözleşme ve anlaşmaların taslaklarını uluslararası normlara uygun, profesyonel ve yüksek standartlarda hazırlama",
        ourServiceDesc2_4:
          "Hukuki durum tespit değerlendirmesi yapma ve raporlama: Hisse senedi satma ve satın almakla ilgilenen yatırımcılara şirketlerin yasalara uyumluluğunu inceleyip, denetledikten sonra bağımsız rapor hazırlayıp sunmak",
        ourServiceDesc2_5:
          "Hukuki görüş hazırlama: Yatırım, finansman müzakereleri, hisse senedi ihracıyla borçlanacak, sermaye çekmeyi planlayan şirketlerin faaliyetlerine doğrulama analizleri yaparak hukuki görüş hazırlama",
        ourServiceDesc3_1:
          "Adli davaların mahkeme süreçleri veya idari davaların yargılanma süreçlerine müvekkillerimizin temsilcisi, avukatı olarak dahil olma, ihlal suçu ve ceza muhakemesi davalarında müvekkillerimizin avukatı olarak katılma, tahkim davalarında müşterilerimizi temsil etmek, mahkeme kararı infazında müşterilerimizi temsil etme gibi hukuki hizmetleri sunmaktayız. Biz müşteri ve müvekkillerimizin ihtiyaç, taleplerini esas alarak hukuki durum araştırmaları yapma ayrıca hukuki ve kurumsal gelişme eğitimleri düzenliyoruz.",
        ourServiceDesc3_2:
          "Ekibimiz, Moğolistan genelinde, başkent Ulanbator ve 21 il, 100 üzeri ilçe, yerleşim, 300,000’den fazla vatandaşa ayrıca Moğolistan Cumhuriyeti Hükümetinin ajansları, kamu ve yerel yönetim kurumları, İş dünyasındaki grup şirketler, Moğolistan Cumhuriyetinin en öndeki şirketleri, Moğolistanın ve Uluslararası sivil toplum kuruluşları gibi 1000’den fazla kuruluşların eğitim ve geliştirme faaliyetlerini organize etmiş tecrübeye sahiptir.",
        ourTeamTitle: "BİZİM TAKIM",
        ourTeamDesc1:
          "Moğolistan yasa ve kanunları, hukuk sistemi, iş dünyası, siyasi ilişkiler hakkında geniş çaplı bilgi ve tecrübeye sahip avukatlar, vergi, şirket yönetimi ve hisse senedi piyasası konusunda uzmanlaşan hukukçulardan oluşan takım  çalışmaktadır.",
        ourTeamDesc2:
          "Danışmanlarımız mahkeme ve savcılıkta 20-30 yıl çalışmış olan hukuk alanında geniş deneyim ve tecrübeye sahip uzman hukukçulardan oluşmaktadır",

        education: "EĞİTİM",
        membership: "ÜYELİK",
        workxp: "İŞ TECRÜBESİ",
        skills: "UZMANLIK",
        languageSkills: "YABANCI DİL",
        proficiency: "MESLEK",

        ourStatTitle: "ETKİMİZ",
        ourStat1: "HUKUKİ YARDIMDA BULUNDUKLARIMIZ",
        ourStat2: "İŞBİRLİĞİ YAPTIĞIMIZ KURUMLAR",
        ourStat3: "MAHKEME DAVALARI",
        ourStat4: "EĞİTİM VE ARAŞTIRMA",

        testimonials1Name: "NARANBAATAR.B",
        testimonials1Title: "Khanbogd khurd Ltd.Şti Genel müdürü",
        testimonials1Desc:
          "Hukuk konularını bu kadar ilgi çekici ve anlaşılır bir şekilde öğretilebileceğini hiç beklemiyordum. Çalışanlarımıza İş hukuku ve çalışma güvenliği hakkında bilmeleri gereken bilgilerin aktarılması ve onların sorularını kapsamlı şekilde yanıtladığınız için teşekkür ederim. Sizinle işbirliğimizi sürdürmekten mutluluk duyuyoruz.",

        testimonials2Name: "BATTULGA.J",
        testimonials2Title: "Galba Urtuu Ltd.Şti müdürü",
        testimonials2Desc:
          'Şirketimiz Oyu-Tolgoi Ltd.Şti’ne tedarik hizmeti sunmaktadır. Oyu-Tolgoi Ltd.Şti tarafından talep edilen hukuk konuları, "Sağlık, İş güvenliği, Çevre koruması" ve  insan kaynakları evraklarındaki tüzük, kararnameleri Uluslararası standartlara uygun hazırlayıp verdiğiniz için çok müteşekkiriz.',

        testimonials3Name: "OTGONBAT.S",
        testimonials3Title: "Bichil Globus Grubu Genel Müdürü",
        testimonials3Desc:
          'Lider olmak istiyorsan, liderlerle çalış" sözündeki gibi profesyonellerle çalışmak hem zamanınızdan hem paranızdan tasarruf ettirir. İşimizi dışarıdan değerlendirerek hukuk konusundaki tavsiye ve desteklerinizi hızlı ve sorumlulukla sağladıklarınız için teşekkür ederiz.',

        partnersTitle: "İŞBİRLİĞİ YAPTIĞIMIZ KURUMLAR",

        contatctUsTitle: "LÜTFEN BİZİMLE İLETİŞME GEÇİN",
        loading: "Yükleniyor...",
        inputName: "İsim",
        inputPhone: "Telefon",
        inputEmail: "E-posta adresi",
        inputMessage: "Ayrıntılı bilgi",
        inputSubmit: "Gönder",
        inputSuccessMessage: "Formunuz başarıyla göderildi. Yakında sizinle iletişime geçeceğiz",

        addressTitle: "ADRES",
        addressFull: "Sukhbaatar duureg, 1th khoroo, Enkhtaivani urgun chuluu, Blue Sky Tower, 11.kat, Ulanbator/ Moğolistan",
        address1: "Ulanbator, Moğolistan",
        address2: "Sukhbaatar duureg, 1th khoroo, Enkhtaivani urgun chuluu",
        address3: "Blue Sky Tower, 11.kat",

        // ШИНЭГЭРЭЛ
        teamShinegerelFname: "SHINEGEREL",
        teamShinegerelLname: "AMARSANAA",
        teamShinegerelTitle: "İdari̇ müdür",
        teamShinegerelDesc1:
          "Shinegerel Amarsanaa 2012 yılından itibaren hukuk alanında çalışmakta olup, firmamızın iş geliştirme, dış ilişkiler ve işbirliği görevlerini başarıyla yürüttüğünün neticesinde kurumumuzun ortağı ve idari yönetim pozisyonuna yükselerek çalışmaktadır.",
        teamShinegerelDesc2:
          "O Yatırımlar, Gayrimenkul, Hisse senedi ve Menkul kıymetler piyasası, Ceza hukuku, Arsa anlaşmazlıkları, Ail eve Miras, Sözleşme hukuku alanlarında uzmanlaşmış olup, hukuk alanındaki araştırmalar, hukuki görüş bildirme, projeleri yönetme gibi deneyimlere sahiptir",
        teamShinegerelDesc3:
          "Shingerel Amarsanaa mesleki bilgi ve becerilerini tam olarak kullanmakta ve hukukçu mesleğinin değerleri, sorumluluğu, etiğine değer vererek müşterilerinin haklarını, yasal çıkarlarını temsil ederek çalışmaktadır",
        teamShinegerelWorkxp1: "",
        teamShinegerelLanguageSkills1: "",
        teamShinegerelEducation1: "",
        teamShinegerelProficiency1: "Hukukçu ve Avukat",
        teamShinegerelProficiency2: "Tercüman",
        teamShinegerelSkill1:
          '"Menkul kıymet piyasası katılımcılarına hukuki danışmanlık hizmeti verme" yetkisine sahip hukukçu – Moğolistan Sermaye Piyasası Düzenleme Kurulu',
        teamShinegerelMembership1: "Moğolistan Barolar Birliği üyesi",
        teamShinegerelMembership2: "Moğolistan Avukatlar Birlği üyesi",
        teamShinegerelMembership3: "Uluslararası Barolar Birlği üyesi",

        // ГҮЛЖАН
        teamGuljanFname: "GULJAN",
        teamGuljanLname: "SHYERKHAN",
        teamGuljanTitle: "Hukukçu",
        teamGuljanDesc1:
          "Guljan Shyerkhan firmamızın dış ilişkileri, sözleşmeler, sigorta, iş hukuku alanlaından sorumlu deneyimli hukukçularımızdan biridir.",
        teamGuljanDesc2:
          'O iş kariyerine ilk "Ard Daatgal" Ltd.Şti’de hukuçu olarak başladığından bu yana Madencilik, Finans sigortası, Menkul kıymetler, Yatırım ve Dış Ticaret alanlarında ağırlıklı çalışarak bilgi, beceri ve tecrübe biriktirmiştir.',
        teamGuljanDesc3:
          "Bugüne kadar 50’den fazla İngilizce ve Türkçe iş sözleşmelerini başarıyla yürütme ve yönetmenin yanı sıra yüksek bedelli büyük ölçekteki sözleşmelerde müşterilerimizin yasal menfaat ve çıkarlarını koruyarak başarıyla çalışmaktadır.",
        teamGuljanWorkxp1: "",
        teamGuljanLanguageSkills1: "",
        teamGuljanEducation1: "Türkiye Cumhuriyeti, Gazi Üniversitesi, Hukuk Fakültesi, Linsans",
        teamGuljanEducation2: "Moğolistan Devlet Üniversitesi, Hukuk Fakültesi, Yüksek lisans",
        teamGuljanEducation3: "Moğolistan Cumhuriyeti Sosyal Bilimler Üniversitesi, İngilizce Tercümanı, Lisans",
        teamGuljanProficiency1: "Hukukçu",
        teamGuljanProficiency2: "Tercüman",
        teamGuljanSkill1:
          'Moğolistan Sermaye Piyasası Düzenleme Kurulu – "Menkul kıymet piyasası katılımcılarına hukuki danışmanlık hizmeti verme" yetkisine sahip hukukçu',
        teamGuljanMembership1: "Moğolistan Barolar Birliği üyesi",

        // БИЛГҮҮНТӨГС
        teamBilguuntugsFname: "BILGUUNTUGS",
        teamBilguuntugsLname: "DAVAASUREN",
        teamBilguuntugsTitle: "Avukat asistanı",
        teamBilguuntugsDesc1:
          "Bilguuntugs Davaasuren firmamızın günlük avukatlık faaliyetlerinde, mahkeme duruşmalarında gerekli teknik ve pratik desteklerde bulunmakta ve gerekli belgeler, veri araştırmalarına analiz yaparak değerlendirme, evrak ve belgeleri hazırlama çalışmalarını yürütmektedir.",
        teamBilguuntugsDesc2:
          "Bilguuntugs üretken, verimli çalışan, iyi performans sergileyen yetenekli ve profesyonel çalışanımızdır. Ayrıca firmamızın hukukçu ve avukatlarının güvenini kazanmış profesyoneldir.",
        teamBilguuntugsWorkxp1: '"BUSINESS LAW FIRM" Ltd.Şti’nde avukat asistanı',
        teamBilguuntugsLanguageSkills1: "Korece",
        teamBilguuntugsEducation1: "Moğolistan Cumhuriyeti İç İşleri Üniversitesi, Hukuk ve Sosyal Bilimler Fakültesi, Hukukçu, Lisans",
        teamBilguuntugsEducation2: "Güney Kore Cumhuriyeti Changshin Üniversitesi, Polis Yönetimi, Lisans",
        teamBilguuntugsProficiency1: "",
        teamBilguuntugsSkill1: "",
        teamBilguuntugsMembership1: "",

        // ОТГОН
        teamOtgonFname: "OTGON",
        teamOtgonLname: "BATJARGAL",
        teamOtgonTitle: "Genel Müdür",
        teamOtgonDesc1:
          "B. Otgon, sadece hukuk firmasının günlük operasyonlarını düzenlemekle kalmaz, aynı zamanda firmanın faaliyetleri ve hukuki davalarında teknik ve pratik destek sağlar. Gerekli belgeleri ve araştırmaları hazırlama ve analiz etme, ayrıca hukuki belgeleri hazırlama konusunda güçlü becerilere sahiptir.",
        teamOtgonDesc2:
          "Bu çalışan, mükemmel verimlilik ve performansa sahip yetenekli bir uzmandır ve firmanın avukatları ve hukuk danışmanlarının güvenini kazanmıştır.",
        teamOtgonWorkxp1: "Genel Müdür – “GOLDEN STIRRUP MINING MACHINERY” LLC (Nisan 2024 – Temmuz 2024)",
        teamOtgonWorkxp2: "Genel Müdür – “GOLDEN SPRING INTERNATIONAL” LLC (Ocak 2024 – Temmuz 2024)",
        teamOtgonWorkxp3: "İnsan Kaynakları Yöneticisi – “GOLDEN SPRING INTERNATIONAL” LLC (Eylül 2022 – Ocak 2024)",
        teamOtgonLanguageSkills1: "İngilizce",
        teamOtgonLanguageSkills2: "Çince",
        teamOtgonEducation1: "İşletme Lisans Derecesi – Ming Dao Üniversitesi, Tayvan",
        teamOtgonEducation2: "Hukuk Lisans Derecesi – Moğolistan Ulusal Üniversitesi Hukuk Fakültesi",
        teamOtgonProficiency1: "",
        teamOtgonSkill1: "",
        teamOtgonMembership1: "",

        // ОТГОНЖАРГАЛ
        teamOtgonjargalFname: "OTGONJARGAL",
        teamOtgonjargalLname: "SUMYA",
        teamOtgonjargalTitle: "Ortak, Avukat",
        teamOtgonjargalDesc1: "S. Otgonjargal, 2024 yılından beri hukuk firmasında ortak ve avukat olarak çalışmaktadır.",
        teamOtgonjargalDesc2:
          "2006 yılından bu yana Moğol Barosu üyesi olup 18 yıldır avukat olarak çalışmaktadır. Yabancı vatandaşlık konularında geniş deneyime sahip, tanınmış ve nitelikli bir avukattır.",
        teamOtgonjargalDesc3:
          "S. Otgonjargal ortağı, vatandaşların ve tüzel kişilerin talep ve ihtiyaçlarına göre mesleki etik kurallarına bağlı kalır, yasalara uygun olarak en uygun hukuki tavsiyeyi sağlar ve hukuki yardım ve avukatlık hizmetleri sağlar.",
        teamOtgonjargalWorkxp1: "",
        teamOtgonjargalLanguageSkills1: "İngilizce",
        teamOtgonjargalLanguageSkills2: "Japonca",
        teamOtgonjargalEducation1: "İkh Zasag Uluslararası Üniversitesi, Lisansı",
        teamOtgonjargalEducation2: "Japonya, Ekonomi Üniversitesi, Yüksek Lisans",
        teamOtgonjargalEducation3: "İşletme Akademisi - Kamu Yönetimi Bölümü",
        teamOtgonjargalEducation4: "Gunma Üniversitesi, Japonya",
        teamOtgonjargalEducation5: "Chuo Üniversitesi, Hukuk Fakültesi, Japonya",
        teamOtgonjargalProficiency1: "Avukat",
        teamOtgonjargalProficiency2: "Kamu yönetimi müdürü",
        teamOtgonjargalProficiency3: "İşletme yönetimi / usta /",
        teamOtgonjargalProficiency4: "Japon çevirmen",
        teamOtgonjargalSkill1: "",
        teamOtgonjargalMembership1: "",

        // АЗБАЯР
        teamAzbayarFname: "AZBAYAR",
        teamAzbayarLname: "TSEEPEL",
        teamAzbayarTitle: "Kurucu ortak",
        teamAzbayarDesc1:
          "Azbayar Tseepel 2016 yılında kurulan şirketin, kurucu ortaklarından olup bugüne kadar firmamızın iş danışmanlığı, hukuk eğitimi ve araştırma ekibine önderlik yaparak başarıyla yönetmektedir.",
        teamAzbayarDesc2:
          "Moğolistam cumhuriyeti Büyük Millet Meclisi ve Hükümet Kabinesi Sekreterliğinde görev yapma deneyiminin yanı sıra iş dünyasında 10 yıldan fazla çalışmış, küçük ve orta ölçekli şirketler, grup şirketlerde / kurucu, yatırımcı veya yönetim kadrosunda/ hukluk, iş yönetimi, strateji planlama, pazarlama yönetimi alanlarında profesyonel danışmanlık hizmetleri sunarak işbirliği yapmaktadır.",
        teamAzbayarDesc3:
          "Ayrıca Bankacılık ve Finans, Menkul kıymetler piyasası, Madencilik, Ticaret hukuku ve Şirket yönetimi, Fikri mülkiyet, İş hukuku, Sözleşme yasaları konusunda çalışmalarını yürütmektedir.",
        teamAzbayarDesc4:
          "Özel hukuk muhakemesi ve İdari yargı davalarında müvekkilleri temsilen avukat olarak katılma, Tahkim işlemlerinde müşterileri temsil etme, Mahkeme kararı icra işleminde müşterileri temsilen katılma gibi hukuki hizmetlerde bulunarak çalışmış deneyimlere sahiptir.",
        teamAzbayarWorkxp1: "",
        teamAzbayarLanguageSkills1: "",
        teamAzbayarEducation1: "",
        teamAzbayarProficiency1: "Hukuçu, Yüksek lisans",
        teamAzbayarProficiency2: "İşletme Yönetimi, Doktora öğrencisi",
        teamAzbayarSkill1:
          '"Menkul kıymet piyasası katılımcılarına hukuki danışmanlık hizmeti verme" yetkisine sahip hukukçu – Moğolistan Sermaye Piyasası Düzenleme Kurulu, MBB"',
        teamAzbayarSkill2: "Vergi hukuku müşaviri – Vergi Müşavirleri Derneği, MBB",
        teamAzbayarSkill3: "Şirket yönetimi –Şirket Yönetimi Ulusal Konseyi",
        teamAzbayarMembership1: "Moğolistan Barolar Birliği üyesi, öğretim görevlisi",
        teamAzbayarMembership2: "Moğolistan Avukatlar Birliği üye avukatı",
        teamAzbayarMembership3: "Uluslararası Hukukçular Birliği üyesi",

        // АЛТАНЦЭЦЭГ
        teamAltantsetsegFname: "ALTANTSETSEG",
        teamAltantsetsegLname: "LUVSAN-OSOR",
        teamAltantsetsegTitle: "Kıdemli müşavir",
        teamAltantsetsegDesc1:
          "Altantsetseg Luvsan-Osor uzun yıllar süren başarılı çalışmaları ve Moğolistan Cumhuriyeti hukuk alanına kattuğı çok değerli katkılarından dolayı devletimiz tarafından Moğolistan Cumhuriyeti Onurlu hukukçusu unvanına layık görülmesi ekibimizin gurur kaynağıdır.",
        teamAltantsetsegDesc2: "",
        teamAltantsetsegDesc3: "1986 yılından itibaren Khentii ili savcılığı",
        teamAltantsetsegDesc4: "1998 yılından itibaren Devlet Başsavcılığında denetim savcısı",
        teamAltantsetsegDesc5:
          "2001-2003 yılları arasında Başkent savcılığında denetim savcısı olarak toplamda 17 yıl savcı görevinde çalışmış deneyime sahip",
        teamAltantsetsegDesc6: "2004-2024 yıllarında Dış İşleri Bakanlığında çakışmaktadır",
        teamAltantsetsegDesc7:
          "Ve şirketimizde Ceza hukuku başdanışmanı, Ceza muhakemesi ve İdari yargı dava duruşmaları, Mahkeme kararı icrası işlemlerinde danışmanlık yapmaktadır",
        teamAltantsetsegWorkxp1: "",
        teamAltantsetsegLanguageSkills1: "",
        teamAltantsetsegEducation1: "Moğolistan Devlet Üniversitesi, Hukuk fakültesi",
        teamAltantsetsegEducation2: "Yönetim Akademilerinden sırasıyla mezun olarak",
        teamAltantsetsegEducation3: "Rusya Federasyonu, Tayvan, ABD, BAE, Çin Halk Cumhuriyeti’nde meslek gelişimi yapmış",
        teamAltantsetsegProficiency1: "Hukukçu/ Yüksek lisans/",
        teamAltantsetsegProficiency2: "Kamu Yönetimi",
        teamAltantsetsegSkill1: "",
        teamAltantsetsegMembership1: "",

        // НАРАНЦЭЦЭГ
        teamNarantsetsegFname: "NARANTSETSEG",
        teamNarantsetsegLname: "MYANGAA",
        teamNarantsetsegTitle: "Kıdemli danışman",
        teamNarantsetsegDesc1: "",
        teamNarantsetsegDesc2:
          "Narantsetseg Myangaa, Selenge ili mahkemesi ve Başkent Özel hukuk temyiz mahkemesinde 38 yıl hakim olarak görev yaptı",
        teamNarantsetsegDesc3:
          "2000 yılından itibaren hakim ve hukukçulara özel hukuk, Özel Hukuk Muhakemesi kanunu konusunda eğitim vermekle beraber Başkentin 9 belediyesi, 21 illerin hakim, savcı, avukat ve diğer hukukçularına devamlı hukukçuluk eğitimi düzenlemektedir",
        teamNarantsetsegDesc4:
          "Ayrıca firmamızın özel hukuk kıdemli danışmanı olarak çalışmakta, özel hukuk muhakemesi ve tahkim davaları görüşmelerinde, mahkeme kararı icra işlemlerinde danışmanlık yapmaktadır.",
        teamNarantsetsegWorkxp1: "",
        teamNarantsetsegLanguageSkills1: "",
        teamNarantsetsegEducation1: "Moğolistan Devlet Üniversitesi, Hukuk Fakültesi",
        teamNarantsetsegProficiency1: "Hukukçu, Yüksek lisans",
        teamNarantsetsegSkill1: "",
        teamNarantsetsegMembership1: "Moğolistan Barolar Birliği üyesi,hukuçu ve eğitmen",

        // ЦОЛМОН
        teamTsolmonFname: "TSOLMON",
        teamTsolmonLname: "ODBAATAR",
        teamTsolmonTitle: "Sözleşme uzmanı",
        teamTsolmonDesc1:
          "Tsolmon Odbaatar kariyerine Legas Ltd.Şti’de  Hukuk araştırmacısı olarak başladığından beri İnşaat sektöründe sözleşme uzmanlığı ve hukuk danışmanı olarak çalışmalarını sürdürmektedir.",
        teamTsolmonDesc2:
          "Firmamızın danışmanlık hizmetlerindeki sözleşmelerin taslaklarını hazırlama görevini üstlenmekte ve Çalışma ilişkileri, Kurumsal yönetim, Fikri mülkiyet uyuşmazlıklarında savunma ekibinde hukuk asistanı olarak görev almaktadır.",
        teamTsolmonWorkxp1: "",
        teamTsolmonLanguageSkills1: "İngilizce",
        teamTsolmonEducation1: "İkh Zasag Uluslararası Üniversitesi, Lisansı",
        teamTsolmonProficiency1: "Hukukçu",
        teamTsolmonSkill1: "",
        teamTsolmonMembership1: "",

        // ХУЛАН
        teamHulanFname: "KHULAN",
        teamHulanLname: "OTGONBAYAR",
        teamHulanTitle: "Muhasebeci",
        teamHulanDesc1: "Khulan Otgonbayar firmamızın günlük mali işlemleri ve muhasebe kayıt işlemlerinden sorumludur.",
        teamHulanDesc2:
          "İlk iş hayatına Vergi Dairesi Genel Müdürlüğünde, Vergi müfettişi olarak başlamıştır. Ayrıca görevinin yanı sıra finans ve muhasebe işlerinde dış kaynak hizmetileri sunmaktadır.",
        teamHulanDesc3: "Görevinde sorumlu, proaktif, samimi görüşlü, yetenekli uzmanızmızdır.",
        teamHulanWorkxp1: "Vergi Dairesi Genel Müdürlüğü, Vergi müfettişi",
        teamHulanWorkxp2: "BUSINESS LAW FIRM Ltd.Şti’de muhasebeci",
        teamHulanLanguageSkills1: "",
        teamHulanEducation1: "",
        teamHulanProficiency1: "Muhasebe",
        teamHulanSkill1: "",
        teamHulanMembership1: "",

        //
        contactUsNow: "Bizimle hemen iletişime geçiniz",
        followUs: "Bizi takip edin",
        pageAbout: "Hakkımızda",
        pageServices: "Hizmetlerimiz",
        pageTeam: "Takımımız",
        pageJoin: "Bize katılın",
        pageContact: "İletişim",

        //
        goBackToHome: "Ana sayfaya git",
        moreInfo: "Daha fazlası",
        getAquote: "Tavsiye al",
        callNow: "Şimdi ara",
        notFound: "SAYFA BULUNAMADI",
        notFoundMsg: "Web sayfası bulunamadığında 404 sayfası açılır ve bu içerik kullanılamayacağını belirten işarettir.",

        //
        bestLawyers: "Tecrübe değerdir",
        homeSection2Title: "Tutkumuz",
        homeSection1Desc: "BLF, profesyonel hukuk ve yönetim danışmanlığı hizmetleri sağlamaya kendini adamıştır.",
        homeSection2Desc:
          "Takımımız her  konuda uzun yıllara dayanan deneyim, bilgi ve beceriye sahip hukukçular, avukatlar, iş danışmanları, eğitmenler ve uzmanlardan oluşmaktadır.",
        homeSection3Desc:
          "Müşterilerimizin, ihtiyaç duydukları anda en uygun ve etkili tavsiye ve yardımı sağlayabileceklerimize inanarak ve onlarla işbirliği yaptığımızın sorumluluk ve farkındalığıyla çalışmak önceliğimizdir.",

        // new!
        ourExperience: "TECRÜBELERİMİZ",
        customerReview: "Müşterileri yorumları",
        historyTitle: "Hakkımızda",
        historyDesc1:
          "Sadece kendimiz ve takım arkadaşlarımızla kalmaksızın sektörümüz ve ülkemizin gelişimine katkıda bulunarak, kendimize has öykümüzü yaratma arzusuyla uzman, esnek, açık ticaret hukuku firmasını kurma yönünde cesurca adım atarak faaliyetimize başladık.",
        historyDesc2:
          "Bu nedenle sadece hukuk firması değil, çalışanlarımız işini seven, işbirliği için pozitif çalışma ortamı yaratan, müşterilerimizin işlerini büyütme ve geliştirmek için el ele çalışabileceği hukuk firmasını yaratmak amacımızdır.",
        historyDesc3:
          "Faaliyetlerimizin kapsamı ve itibarı büyüdükçe, toplumumuzda silinmez değer ve itibar yaratma misyonumuzu her zaman yerine getirmeye çalışacağız.",

        services1Title: "Hukuk danışmanlığı hizmetleri",
        services2Title: "Avukatlık",
        services3Title: "Eğitim ve Araştırma",
        services4Title: "Yönetim danışmanlığı hizmeti",

        experience: "Tecrübelerimiz",
        expereinceDesc: "Çalıştığımız sektörler ve alanlar",

        xpTitle1: "Kurumsal Yönetim",
        xpTitle2: "Sermaye piyasaları ve menkul kıymetler (halka arzlar ve ikincil arzlar dahil)",
        xpTitle3: "Yatırım / özel ve ortaklık/",
        xpTitle4: "Sermaye finansmanı",
        xpTitle5: "Varlık yönetimi",
        xpTitle6: "İş yapısının değiştirilmesi ve yeniden yapılandırma",
        xpTitle7: "Birleşme ve satın alma",
        xpTitle8: "Finans ve Bankacılık",
        xpTitle9: "Madencilik ve Enerji",
        xpTitle10: "İnşaat ve Alt yapı",
        xpTitle11: "Arsa ve Kentsel dönüşüm",
        xpTitle12: "Maden araması ve işletmesi",
        xpTitle13: "Kamu hizmetleri ve İdari davalar",
        xpTitle14: "Vergi",
        xpTitle15: "İhale ve ihale elemeleri",
        xpTitle16: "Gayrimenkul",
        xpTitle17: "Göç",
        xpTitle18: "Firkri mülkiyet",
        xpTitle19: "Tüketici hakları ve Rekabet",
        xpTitle20: "Ticaret ve sözleşme anlaşmazlıkları",
        xpTitle21: "İş hukuku anlaşmazlıkları",
        xpTitle22: "Lisans ve izinlerin alınması",

        fields: "Alanlar",
        fieldOfStudy: "Eğitim alanlarımız",

        contentAdvocacyDesc1: "Amacımız dava ve anlaşmazlıkları en makul çözümlerle hızlı ve tasarruflu bir şekilde çözmektir",
        contentAdvocacyDesc2:
          "Avukatlar takımımız çok çeşitli ve karmaşık hukuki sorunları çözme ve başarıyla sonuçlandırmış uzun yılların tecrübesine sahiptir.",
        contentAdvocacyDesc3:
          "Müşterilerimizin hakları ve meşru menfaatleri doğrultusunda, uyuşmazlıkların çözümüne yönelik müzakere stratejileri geliştirerek çalışıyoruz.",
        contentAdvocacyDesc4:
          "Avukatlarımız mevzuatı araştırıp hazırlama, yargılama öncesi ve mahkeme işlemlerinin her aşamasında sizi temsil edecek bilgi, beceri ve deneyime sahiptir.",
        contentAdvocacyDesc5:
          "Duruşma aşamasında sorundaki büyük resme bakmaya, daha iyi sonuçlara ulaştıracak stratejiler geliştirmeye ve hiçbir küçük ayrıntıyı kaçırmadan, hiçbir fırsatı zayi etmeden tam ve etkili çalışmaya önem veriyoruz.",
        contentAdvocacyDesc6:
          "Hukukçu ve avukatlarımız daha iyi kararlar alabilmeleri için, sorunlara farklı açılardan bakarak, fikir alış verişinde bulunarak,  işbirliği yapmaları neticesinde müşterilerimize daha yenilikçi ve yaratıcı çözümler sunabilecek daha etkin takımlar oluşturduklarına inanıyoruz.",

        advocacyFieldsTitle1: "Arsa anlaşmazlıkları",
        advocacyFieldsTitle2: "Sözleşme anlaşmazlıkları",
        advocacyFieldsTitle3: "Ortaklık ve Hissedarlar anlaşmazlıkları",
        advocacyFieldsTitle4: "Gayrimenkul",
        advocacyFieldsTitle5: "Kentsel dönüşüm",
        advocacyFieldsTitle6: "Miras",
        advocacyFieldsTitle7: "Aile",
        advocacyFieldsTitle8: "İş uyuşmazlıkları",
        advocacyFieldsTitle9: "Fikri mülkiyet",
        advocacyFieldsTitle10: "Kamu hizmeti",
        advocacyFieldsTitle11: "Siyasi seçimler",
        advocacyFieldsTitle12: "Gümrük ve Vergi",
        advocacyFieldsTitle13: "İhale elemeleri",
        advocacyFieldsTitle14: "Ruhsat ve devlet kayıt işlemleri",
        advocacyFieldsTitle15: "Şirket ve tüzel kişilik",
        advocacyFieldsTitle16: "Zarara uğratma ve söleşme harici yükümlülükler",
        advocacyFieldsTitle17: "Yabancılar ve göç idaresi",
        advocacyFieldsTitle18: "Ceza suçları",
        advocacyFieldsTitle19: "Mahkeme davaları",
        advocacyFieldsTitle20: "Tahkim davaları",

        contentConsultingDesc1:
          "BLF, hukuki bilgi, beceri, pratik deneyim ve yöntemlerine dayanarak, müşterilerimizin günlük işleri ve önemli iş kararlarını etkileyebilecek önemli bilgi ve tavsiyeleri zamanında profesyonel düzeyde sunmayı amaçlamaktadır.",
        contentConsultingDesc2:
          "Hukukçu ve avukatlarımız giderek hızla değişen sosyal, ekonomik ve iş ortamının karşı karşıya olduğu zorluklar hakkında sahip olduğu net fikir ve bilgi ile daha hızlı, daha verimli ve erişilebilir hizmetler sunmayı amaçlamaktadır.",
        contentConsultingDesc3:
          "Her bir müşterimizin ihtiyaç, gereksinimleri ile vizyon, politika, misyonunu dikkate alarak hukuki ve iş yönetimi bilgi ve  deneyimlerimizi birleştirerek hizmet sunmakla diğerlerinden farklılaşıyoruz.",
        contentConsultingDesc4:
          "Her müşterimizin işi diğerlerinden farklı ve kendine has özelliklere sahip olduğundan, mümkün olan tüm seçenekleri hesaba katarak, kanun ve yasalara doğrultusunda her bir müşterimizin hedef, yasal hak ve menfaatlerine uygun, belirli sonuçlara ulaşmak için kararlı, yenilikçi, hazırlıklı ve titiz çalışmak önceliğimizdir.",

        consultingFieldsTitle1: "Madencilik ve mineraller",
        consultingFieldsTitle2: "İnşaat, Enerji, Altyapı",
        consultingFieldsTitle3: "Finans ve Bankacılık",
        consultingFieldsTitle4: "Menkul kıymetler ve Yatırım",
        consultingFieldsTitle5: "Vergi ve Sigorta",
        consultingFieldsTitle6: "Gayrimenkul ve tescil",
        consultingFieldsTitle7: "Fikri mülkiyet",
        consultingFieldsTitle8: "Yabancı yatırımı",
        consultingFieldsTitle9: "Kurumsal yönetim",

        contentResearchDesc1:
          "Hukuk eğitimimiz katılımcılara hem teorik hem de pratik olarak etkileşimli bir eğitim metodu sunmakla beraber müşterilerimizin özel ihtiyaçlarına ve gereksinimlerine göre uyarlanmış eğitim içeriği sunmak farklılığımızdır.",
        contentResearchDesc2:
          "Eğitimimizde gerçek vakalara dayalı, pratik, katılımcı metoda ağırlık verdiğimizden edindiğiniz bu bilgiyi iş ve çalışma alanlarınzda kullanmak daha uygulanabilir ve kolaydır.",

        researchFieldsTitle1: "Sözleşme hukuku",
        researchFieldsTitle2: "İş hukuku",
        researchFieldsTitle3: "Vergi hukuku",
        researchFieldsTitle4: "Fikri mülkiyet hukuku",
        researchFieldsTitle5: "Şirket yönetim hukuku",
        researchFieldsTitle6: "İşletme ve İnsan hakları",

        contentManagementDesc1Title: "1. SİYASET YÖNETİMİ",
        contentManagementDesc2Title: "2.İŞ YÖNETİMİ",
        contentManagementDesc1:
          "Siyasi yönetim, hem profesyonel hem çok yönlü siyasi faaliyetleri kapsayan  geniş çaplı kavram olup, sürekli değişen ve gelişen bir alandır.",
        contentManagementDesc2:
          "Bu alan eylem kampanyası yönetimi, strateji planlama, siyaset araştırmaları, halkla ilişkiler taktiği, yasal düzenlemeler gibi çeşitli faaliyetleri kapsar.",
        contentManagementDesc3:
          "İş yönetimi danışmanlık hizmetimiz, kurumların faaliyetlerini iyileştirme, üretkenliği artırma, değişim yönetiminin uygulanması, kurumun hedeflerine ulaşması için yardımcı olacak bağımsız danışmanlık hizmetidir.",
        contentManagementDesc4:
          "İş sürecinde karşılaşılan sorunları tespit etme teşhisleri yapma, işi daha verimli ve etkin bir şekilde gerçekleştirecek yöntem, teknoloji, optimum sistem uygulamaları ve çözüm tavsiyelerinde bulunuyoruz.",

        managementFieldsTitle: "Siyasi yönetim",
        managementFieldsTitle2: "İş yönetimi",

        managementFields1: "Seçim kampaniyası yönetimi",
        managementFields2: "Stratejik planlama",
        managementFields3: "Siyasi marka ve mesaj üretme",
        managementFields4: "Siyasi Halkla İlişkiler teknolojisi",
        managementFields5: "Kanun taslağı hazırlama",
        managementFields6: "Hukuki görüş bildirme",

        managementFields2_1: "Kurumsal yönetim",
        managementFields2_2: "Grup şirketlerin yönetimi",
        managementFields2_3: "İş stratejisi",
        managementFields2_4: "Pazarlama yönetimi danışmanlık hizmetleri sunmaktayız",

        joinUsTitle: "BİZE KATILIN",
        joinUsSlogan: "Bir hukuk firmasının esas gücü, çalışanlarıdır.",
        joinUsDesc1:
          "BLF, olarak yaptığımız her şeyi mükemmelliğe ulaştırmaya özen göstermekle beraber takımımızın tüm üyeleri, çok yönlü, yaratıcı, çalışkan olup, müşterilerimiz ve toplumumuz için fark yaratabilmektedir.",
        joinUsDesc2:
          "Kariyerlerinin her aşamasında iyi hukukçuları bulan, dinamik ve büyüyen bir hukuk firmasıyız. Firmamız, hukukçular için ilgi çekici bir çalışma ortamında, sınamaların üstesinden gelmesi ve hızlı gelişmesi ve için harika bir yerdir.",
        joinUsDesc3:
          "Aramıza katılmak isteyen hukukçu ve avukatlar arasından, şirketimizin geçmişine ve kültürüne uyum sağlayabilen, bir takımın parçası olarak başarılı bir şekilde çalışabilen, ortak hedeflerimiz için motivasyon ve kararlılıkla çaba sarf edecek, ilgi çekici, eğlenceli, dinamik, özel ve iş hayatı dengesini koruyabilen, misyon sahibi  harika kişileri seçmeye özen gösteriyoruz.",
        joinUsDesc4:
          "Temel değerlerimizi paylaşarak ve mükemmel takımımızın bir parçası olmak istiyorsanız bizimle contact@businesslawfirm.mn adresinden iletişime geçin.",
      },
    },
  },
  lng: "mn", // Default language
  fallbackLng: "mn", // Fallback language in case the requested language is not available
  interpolation: {
    escapeValue: false, // By default, escape HTML characters
  },
});

export default i18n;
